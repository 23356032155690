import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.css';


const LMSInteraction = props => {
  const { className, checked, next, visible, icon, text, onClick, disabled } = props;

  return (
    <button
      className={classnames({
        'lms-interaction': true,
        'state-inactive': !visible,
        'state-checked': checked,
        'next': next,
        [className]: className
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {icon &&
        <div className="lms-interaction-icon">
          {icon}
        </div>
      }
      {text}
    </button>
  );
};

LMSInteraction.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.node,
  visible: PropTypes.bool,
  checked: PropTypes.bool,
  next: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default LMSInteraction;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { translate } from '../../utils';
import Header from '../../components/header';
import Todo from '../../components/todo';
import './styles.css';

class ToDoList extends React.Component {

  render() {
    const { todos } = this.props;
    return (
      <section className={classNames({ 's-todolist': true })}>
        <Header
          className="header"
          hamburger
          logo
          logoColor="var(--color-1)"
          hamburgerColor="var(--body-color)"
        />
        <section className="container">
          <h1 className="todolist__title">{translate('TODO_TITLE_TEXT')}</h1>
          {todos.length > 0
            ? <p className="todolist__subtitle">{translate('TODO_SUBTITLE_TEXT')}</p>
            : <p className="todolist__subtitle">{translate('TODO_LIST_EMPTY_MESSAGE')}</p>
          }
          <div className="todolist__todos">
            {todos.map((todo,index) =>
              <Todo
                key={index}
                uuid={todo.data.uuid}
                title={todo.data.title}
                duration={todo.data.duration}
                mandatory={todo.data.mandatory}
                completed={!!todo.completed_at} />)
            }
          </div>
        </section>
      </section>
    );
  }
}

ToDoList.propTypes = {
  history: PropTypes.object,
  todos: PropTypes.array,
  journeys: PropTypes.array,
};

const mapStateToProps = state => {
  return {
    todos: (state.todos || []).filter(todo => !todo.completed_at),
    journeys: state.journeys,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ToDoList);

import React from 'react';
import { translate } from '../../utils';
import Button from '../button';
import PropTypes from 'prop-types';

import './styles.css';

class TimeoutPopup extends React.Component {
  render() {
    const { active, onRestart, onDone } = this.props;
    return active && (
      <div className="c-modal-container">
        <div className="overlay"></div>
        <div className="modal">
          <div className="modal__content">
            <h1 className="modal__heading">
              {translate('TIMEOUT_POPUP_HEADING')}
            </h1>
            <p className="modal__question">{translate('TIMEOUT_POPUP_QUESTION_TEXT')}</p>
          </div>
          <div className="modal__buttons">
            <Button text={translate('Try again')} onClick={onRestart}/>
            <Button className="outlined" text={translate('Back to dashboard')} onClick={onDone}/>
          </div>
        </div>
      </div>
    );
  }
}

TimeoutPopup.propTypes = {
  active: PropTypes.bool,
  onRestart: PropTypes.func,
  onDone: PropTypes.func,
  data: PropTypes.object,
};

export default TimeoutPopup;

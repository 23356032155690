import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Div100vh from 'react-div-100vh';
import { Link } from 'react-router-dom';

import { postLogin } from '../../store/app/actions';
import { translate } from '../../utils';
import SectionTitle from '../../components/section_title';
import FormField from '../../components/form_field';
import Button from '../../components/button';
import Header from '../../components/header';
import './styles.css';


class Login extends React.Component {

  state = {
    email: '',
    password: '',
    submitted: false
  }

  submit(e) {
    e.preventDefault();
    this.props.postLogin({ email: this.state.email, password: this.state.password });
    this.setState({ submitted: true });
  }

  render() {
    const { errors } = this.props;
    const { email, password, submitted } = this.state;

    return (
      <Div100vh style={{minHeight: '100rvh'}} className="s-auth">
        <Header companyLogo logoColor="var(--color-1)" />

        <div className="content-container">
          <SectionTitle text={translate('LOGIN_TITLE')} />
          <p className="description">{translate('LOGIN_CTA')}</p>
          <form className="form-field-group" onSubmit={this.submit.bind(this)} >
            <FormField
              errors={errors && errors.email}
              placeholder={translate('EMAIL')}
              type="email"
              required
              value={email} onChange={(e) => { this.setState({ email: e.target.value }) }}
            />
            <FormField
              errors={errors && errors.password}
              placeholder={translate('PASSWORD')}
              type="password"
              value={password}
              onChange={(e) => { this.setState({ password: e.target.value }) }}
            />

            {submitted && (errors && errors.non_field_errors && errors.non_field_errors.length > 0) &&
              <div className="error-container">
                {errors.non_field_errors.map((error, index) =>
                  <p key={index}>{translate(error)}</p>
                )}
              </div>
            }

            <Button elem="button" text={translate('LOGIN_BUTTON_TEXT')} />
            <Link className="footer-link" to={'/auth/forgot-pass'}>{translate('FORGOT_PASSWORD?')}</Link>
          </form>
        </div>
      </Div100vh>
    );
  }
}

Login.propTypes = {
  errors: PropTypes.object,
  postLogin: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    errors: state.errors.login
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  postLogin
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Login);

import '@babel/polyfill';
import './dynamic-public-path';
import './PWAInstaller';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { brandStyle } from './brand-config';
import { getApi } from './api';
import { rootElement } from './root';
import { store, history } from './store';
import App from './app';
import * as serviceWorker from './serviceWorker';
import './styles/index.css';

smoothscroll.polyfill();
brandStyle();

getApi().apiPromise.then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route path="/" component={App} />
      </ConnectedRouter>
    </Provider>
    , rootElement
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { translate } from '../../utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IconSmileSad, IconSmileNeutral, IconSmileHappy, IconProfile } from '../../components/icons';
import './styles.css';

class ReportingUser extends React.Component {
  state = {
    help_needed: false,
  }

  fetchLatestWhatsup(whatsupstring) {
    const whatsupObject = whatsupstring && JSON.parse(whatsupstring);
    const whatsupArray = whatsupObject && Object.values(whatsupObject);
    const latestWhatsup = whatsupArray[whatsupArray.length -1];
    this.setState({
      latestWhatsup,
      help_needed: latestWhatsup.needs && !latestWhatsup.needsSeen && true,
    });
  }

  componentDidMount() {
    if(this.props.whatsupstring) {
      this.fetchLatestWhatsup(this.props.whatsupstring);
    } else {
      return;
    }
  }

  render() {
    const { name, jobtitle, imageUrl, progress, id } = this.props;
    const { latestWhatsup, help_needed } = this.state;

    return (
      <Link to={`/reporting/${id}`} className="c-reporting-user">
        <div className="c-reporting-user__img-container">
          {imageUrl ? <img className="c-reporting-user__img" src={imageUrl} />
            : <IconProfile />
          }
        </div>
        <div className="c-reporting-user__text">
          <p className="c-reporting-user__name">{name}</p>
          <p className="c-reporting-user__jobtitle">{jobtitle}</p>
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{ width: `${progress}%` }}>
            </div>
          </div>
          {help_needed &&
            <p className="c-reporting-user__help">{translate('REPORTING_HELP_NEEDED')}</p>
          }
        </div>
        <div className="c-reporting-user__mood">
          {(latestWhatsup && latestWhatsup.overall === 'sad') && <IconSmileSad />}
          {(latestWhatsup && latestWhatsup.overall === 'neutral') && <IconSmileNeutral />}
          {(latestWhatsup && latestWhatsup.overall === 'happy') && <IconSmileHappy />}
          {latestWhatsup && <p>{translate('REPORTING_USER_MOOD')}</p>}
        </div>
      </Link>
    )
  }
}

ReportingUser.propTypes = {
  name: PropTypes.string,
  jobtitle: PropTypes.string,
  imageUrl: PropTypes.string,
  progress: PropTypes.number,
  whatsupstring: PropTypes.string,
  id: PropTypes.number,
  help_needed: PropTypes.bool,
};

export default(ReportingUser);


import React from 'react';
import PropTypes from 'prop-types';

import method from '../work-methods';


class TemplateChecklist extends React.Component {

  componentDidMount() {
    this.props.next();
  }

  componentWillUnmount() {
    this.props.questionData && this.props.updateAnswer(this.props.questionData.answer);
  }

  render() {
    const { data, questionData } = this.props;
    const classes = ['s-module-section t-checklist'];
    const checklist = { ...data.checklist, editable: true };

    return (
      <section className={classes.join(' ')}>
        <main className="container">
          <div className="inner-container">
            <div className="text-container">
              {data.heading && <method.LMSHeading title={data.heading} />}
              {data.text && <method.LMSText text={data.text} />}
            </div>

            <div className="checklist-wrapper">
              <div className="checklist">
                {data.checklist.title &&
                  <method.LMSHeading className="checklist-heading" title={data.checklist.title} />
                }
                <method.LMSChecklist
                  {...this.props}
                  userData={questionData}
                  content={checklist}
                  uuid={data.checklist.uuid}
                  options={data.checklist.items}
                />
              </div>
            </div>
          </div>
        </main>
      </section>
    )
  }
}

TemplateChecklist.propTypes = {
  updateAnswer: PropTypes.func,
  questionData: PropTypes.object,
  next: PropTypes.func,
  data: PropTypes.object,
};

export default TemplateChecklist;

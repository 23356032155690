import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';

import { translate } from './utils';
import { postFlexibleFields } from './store/app/actions';
import { rootProps } from './root';
import ModulePlayer from './containers/module_player';
import Dashboard from './containers/dashboard';
import ToDoList from './containers/todolist';
import Journey from './containers/journey';
import Reporting from './containers/reporting';
import ReportingDetail from './containers/reporting/reporting_detail';
import Intro from './containers/intro';
import ConfirmPass from './containers/auth/confirm-pass';
import ForgotPassword from './containers/auth/forgot-pass';
import Login from './containers/auth/login';
import SplashScreen from './components/splashscreen';
import Whatsup from './containers/whatsup';
import Settings from './containers/settings';
import Intake from './containers/intake';
import EditProfile from './containers/settings/edit';
import Loader from './components/loader';
import Faq from './containers/faq';
import Feedback from './containers/feedback';
import Notifications from './containers/notifications';
import CelebrationPopup from './components/celebration_popup';
import WhoIsWho from './containers/who_is_who';

const routeAnimationKey = (path) => {
  // TODO: find a cleaner way to fix the animation endPath check method.
  const endPath = path.slice(-5);
  if (path.indexOf('/todo') === 0 && endPath.includes('/')) {
    return '/todo';
  }

  return path.split('/')[1] || '/';
};

class App extends React.Component {
  state = {
    splash: true,
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ splash: false });
    }, 2000);
  }

  render() {
    const { location, authenticated, loading, firstVisit, startDate } = this.props;
    const { splash } = this.state;
    const preview = rootProps.lmsPreview;
    const loginRequired = !authenticated && !preview && !location.pathname.includes('/auth');

    const helmet = (
      <Helmet>
        <title>{translate('Take Away')}</title>
      </Helmet>
    );

    return (
      <div className="s-app">
        {helmet}
        {!preview && <SplashScreen active={splash} />}
        {preview && <Redirect to={`/todo/${window[preview].uuid}/1`} />}
        {loginRequired && <Redirect to={'/auth/login'} />}

        {/* Only show whatsup questionnaire for new hires, not the managers who are monitoring the new hires in the same platform */}
        {(!splash && authenticated && firstVisit === false && startDate) &&
          <Whatsup history={this.props.history} />
        }

        <CelebrationPopup />

        {!splash &&
          <TransitionGroup component={null}>
            <CSSTransition classNames="viewswitch" timeout={500} key={routeAnimationKey(location.pathname)}>
              <Switch location={location}>
                <Route exact={true} path="/todo/:moduleUuid/:index" component={ModulePlayer} />
                <Redirect exact={true} from="/todo/:moduleUuid" to="/todo/:moduleUuid/1" />

                <Route path="/auth/register/:uid/:token" component={ConfirmPass} />
                <Route path="/auth/forgot-pass/:uid/:token" component={ConfirmPass} />
                <Route path="/auth/forgot-pass" component={ForgotPassword} />
                <Route path="/auth/login" component={Login} />

                <Route path="/faq" component={Faq} />
                <Route path="/feedback" component={Feedback} />
                <Route path="/intake" component={Intake} />
                <Route path="/intro" component={Intro} />
                <Route path="/todos" component={ToDoList} />
                <Route exact={true} path="/reporting" component={Reporting} />
                <Route path="/reporting/:userId" component={ReportingDetail} />
                <Route path="/notifications" component={Notifications} />
                <Route path="/journey/:journeyId" component={Journey} />
                <Route path="/settings" component={Settings} exact/>
                <Route path="/settings/edit" component={EditProfile} />
                <Route path="/whoiswho" component={WhoIsWho} />
                <Route exact={true} path="/" component={Dashboard} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        }
        <Loader text={translate('APP_LOADER_MSG')} loading={loading} />
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.object,
  authenticated: PropTypes.bool,
  outroViewed: PropTypes.bool,
  history: PropTypes.object,
  celebration: PropTypes.object,
  firstVisit: PropTypes.bool,
  isManager: PropTypes.bool,
  startDate: PropTypes.string,
  hideCelebrationModal: PropTypes.func,
};

const mapStateToProps = state => {
  const user = state.userData;
  const startDate = (user && user.profile_flexible_field) && (user.profile_flexible_field.start_date ? new Date(user.profile_flexible_field.start_date) : undefined);
  const isManager = (user && user.profile_flexible_field) && user.profile_flexible_field.is_manager;

  return {
    authenticated: state.user.authenticated,
    outroViewed: state.flexibleFields.outro_viewed,
    loading: state.loader.completeModule,
    firstVisit: (user && user.profile_flexible_field) && user.profile_flexible_field.first_visit,
    currentDate: new Date(),
    celebration: state.celebration,
    isManager,
    startDate,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  postFlexibleFields
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);

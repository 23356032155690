import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createHashHistory } from 'history';
import { parse } from 'query-string';

import { getApi, setApi, setScormApi } from '../api';
import appSaga from './app/sagas';
import appReducer from './app/reducers';
import lmsSaga from './module_player/sagas';
import lmsReducer from './module_player/reducers';
import dashboardSaga from './dashboard/sagas';
import dashboardReducer from './dashboard/reducers';
import userSaga from './user/sagas';
import userReducer from './user/reducers';
import progressReducer from './progress/reducers';
import progressSaga from './progress/sagas';
import trackingReducer from './tracking/reducers';
import trackingSaga from './tracking/sagas';
import { rootProps } from '../root';
import { API_SCHEMA_URL } from '../variables';

const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(history), sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const hash = window.location.hash;
const hashQuery =
  hash.indexOf('?') !== -1 ? hash.slice(hash.indexOf('?')) : null;
const queryParams = parse(window.location.search);
const hashQueryParams = parse(hashQuery);
const nextUrl =
  hashQueryParams.nextUrl ||
  hashQueryParams.lmshref ||
  queryParams.nextUrl ||
  queryParams.lmshref ||
  null;

const rootReducer = combineReducers({
  ...appReducer,
  ...lmsReducer,
  ...dashboardReducer,
  ...userReducer,
  ...progressReducer,
  ...trackingReducer,
  nextUrl: (state = nextUrl) => state,
  router: connectRouter(history)
});

if (!rootProps.lmsPreview) {
  if (rootProps.scorm) {
    setScormApi();
  } else {
    setApi(API_SCHEMA_URL);
  }
} else if (rootProps.scorm) {
  localStorage.setItem(
    'content',
    JSON.stringify(window[rootProps.lmsPreview].content)
  );
  localStorage.setItem(
    'modules',
    JSON.stringify(
      window[rootProps.lmsPreview].children.map(m => ({
        ...m,
        media_url: ''
      }))
    )
  );
  setScormApi(false);
}

const store = createStore(
  rootReducer,
  {
    moduleContent:
      rootProps.lmsPreview && !rootProps.scorm
        ? { [window[rootProps.lmsPreview].uuid]: window[rootProps.lmsPreview] }
        : {}
  },
  enhancer
);

function* rootSaga() {
  yield all([
    appSaga(),
    lmsSaga(),
    dashboardSaga(),
    userSaga(),
    progressSaga(),
    trackingSaga()
  ]);
}

getApi().apiPromise.then(() => {
  sagaMiddleware.run(rootSaga);
});


export { store, history };

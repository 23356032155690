import * as TYPES from './types';


export const changeUserPassword = (payload) => ({
  type: TYPES.CHANGE_USER_PASSWORD,
  payload
});

export const changeUserSettings = (payload, intake) => ({
  type: TYPES.CHANGE_USER_SETTINGS,
  payload,
  intake
});

export const getNotificationSettings = () => ({
  type: TYPES.GET_NOTIFICATION_SETTINGS,
});

export const updateNotificationSettings = (payload) => ({
  type: TYPES.UPDATE_NOTIFICATION_SETTINGS,
  payload
});

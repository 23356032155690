import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { translate } from '../../utils';

import { IconClock, IconCheck } from '../icons';
import './styles.css';


class Module extends React.Component {
  renderLink(locked, link, inner) {
    return !locked ?
      <Link to={link}>
        {inner}
      </Link>
      : inner
  }

  render() {
    const { uuid, className, title, journey, completed, locked, mandatory, img, duration } = this.props;

    return this.renderLink(locked, `/todo/${uuid}`,
      <article className={classnames({ 'c-module': true, 'state-incomplete': !completed, [className]: className })}>
        <div className="c-module__img-container">
          <img className="c-module__img" src={img} alt="module cover"/>
        </div>
        <div className="c-module__content">
          <h2 className="c-module__title">{title}</h2>
          <p className="c-module__journey">{journey}</p>
          {duration &&
            <p className="c-module__duration">
              <IconClock className="c-module__clock" size={16} />
              {duration} {translate('MODULE_DURATION_TEXT')}
            </p>
          }
          {mandatory && <p className="c-module__mandatory">{translate('MODULE_MANDATORY_TEXT')}</p>}
        </div>
        {completed &&
          <div className="c-module__completed">
            <IconCheck />
          </div>
        }
      </article>
    )
  }
}

Module.propTypes = {
  className: PropTypes.string,
  uuid: PropTypes.string,
  title: PropTypes.string,
  journey: PropTypes.string,
  img: PropTypes.string,
  completed: PropTypes.bool,
  locked: PropTypes.bool,
  mandatory: PropTypes.bool,
  duration: PropTypes.number,
};

export default Module;

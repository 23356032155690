import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Button from '../button';
import Nav from './nav';
import { IconArrowLeft, IconHamburger, HeaderLogoTakeaway , LogoTakeaway } from '../icons';
import './styles.css';


class Header extends React.Component {
  state = {};

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 30) { this.setState({ scrolled: true }); }
      if (this.state.scrolled && window.pageYOffset < 30) { this.setState({ scrolled: false }); }
    });
  }

  back() {
    this.props.history.goBack();
  }

  toggleNav() {
    this.setState({ navActive: !this.state.navActive });
  }

  render() {
    const { className, back, hamburger, todos, unreadNotifications, companyLogo, logo, logoColor, hamburgerColor } = this.props;
    const { navActive, scrolled } = this.state;
    const notificationsAvailable = !!todos || !!unreadNotifications;

    return (
      <header className={classNames({
        's-header': true,
        'has-burger': hamburger,
        'has-logo': logo,
        'scrolled': scrolled,
        [className]: className
      })}>
        {back &&
          <Button
            className="back-btn"
            icon={<IconArrowLeft />}
            onClick={this.back.bind(this)}
          />
        }

        {companyLogo &&
          <LogoTakeaway
            className="logo"
            color={logoColor}
          />
        }

        {logo &&
          <Link to="/">
            <HeaderLogoTakeaway
              className="logo"
              color={logoColor}
            />
          </Link>
        }

        {hamburger &&
          <>
            <div className="hamburger">
              {notificationsAvailable &&
                <span className="hamburger__notification">{todos + unreadNotifications}</span>
              }
              <Button
                className="hamburger__button"
                icon={<IconHamburger color={hamburgerColor} size={20} />}
                onClick={this.toggleNav.bind(this)}
              />
            </div>

            <Nav
              active={navActive}
              toggle={this.toggleNav.bind(this)}
              todos={todos}
            />
          </>
        }
      </header>
    );
  }
}

Header.propTypes = {
  className: PropTypes.string,
  logoColor: PropTypes.string,
  hamburgerColor: PropTypes.string,
  back: PropTypes.bool,
  companyLogo: PropTypes.bool,
  logo: PropTypes.bool,
  hamburger: PropTypes.bool,
  history: PropTypes.object,
  todos: PropTypes.number,
  unreadNotifications: PropTypes.number,
};

const mapStateToProps = state => {
  const todos = (state.todos || []).filter(todo => !todo.completed_at);
  const notifications = state.notifications && state.notifications.results;
  const unreadNotifications = notifications && notifications.filter(notification => notification.unseen).length;
  return {
    todos: todos.length > 0 ? todos.length : null,
    unreadNotifications: unreadNotifications ? unreadNotifications : null,
  };
};

export default connect(mapStateToProps)(Header);

import * as TYPES from './types';

const initialState = {
  currentModuleAttempt: null
};

const tracking = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.MODULE_ATTEMPT:
      return {
        ...state,
        currentModuleAttempt: {
          moduleUuid: action.uuid
        }
      }
    case TYPES.CLEAR_MODULE_ATTEMPT:
      return {
        ...state,
        currentModuleAttempt: null
      }
    default:
      return state;
  }
}

export default {
  tracking
};

export const getCurrentModuleAttempt = state => state && state.tracking && state.tracking.currentModuleAttempt;
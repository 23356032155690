import * as TYPES from './types';
import {
  USER_AUTHENTICATED_SUCCESS,
  LOGOUT_SUCCESS,
} from '../app/types';


const user = (state = {}, action) => {
  switch (action.type) {
    case TYPES.CHANGE_USER_PASSWORD:
      return {
        ...state
      };
    case TYPES.CHANGE_USER_SETTINGS:
      return {
        ...state
      };
    case USER_AUTHENTICATED_SUCCESS:
      return {
        ...state,
        authenticated: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        authenticated: false
      };
    default:
      return state;
  }
};

const settings = (state = [], action) => {
  switch (action.type) {
    case TYPES.GET_NOTIFICATION_SETTINGS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};


export default {
  user,
  settings,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetPassword } from '../../store/app/actions';
import { translate } from '../../utils';
import SectionTitle from '../../components/section_title';
import FormField from '../../components/form_field';
import Button from '../../components/button';
import Header from '../../components/header';
import Message from '../../components/message';
import './styles.css';


class ForgotPassword extends React.Component {

  state = {
    email: '',
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.resetPassword(this.state);
    this.setState({ submitted: true });
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { errors, success } = this.props;
    const { submitted } = this.state;

    return (
      <div className="s-auth">
        <Header companyLogo logoColor="var(--color-1)" />

        <div className="content-container">
          <Message text="FORGOT_PASS_SUCCESS_MSG" active={success && submitted} success />

          <SectionTitle text={translate('FORGOT_PASSWORD?')} />
          <p className="description">{translate('FORGOT_PASS_CTA')}</p>
          <form className="form-field-group" onSubmit={this.handleSubmit.bind(this)}>
            <FormField
              errors={errors && errors.email}
              placeholder={translate('EMAIL')}
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleInputChange.bind(this)}
              required
            />
            <Button text={translate('Send')} elem="button" />
            <Link className="footer-link" to={'/auth/login'}>{translate('BACK_TO_LOGIN')}</Link>
          </form>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  errors: PropTypes.object,
  success: PropTypes.bool,
  match: PropTypes.object,
  resetPassword: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    errors: state.errors.forgotPass,
    success: state.success.forgotPass,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  resetPassword,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

import * as TYPES from './types';
import { select, put, takeLatest, all } from 'redux-saga/effects';
import { rootProps } from '../../root';
import { getCurrentModuleAttempt } from './reducers';
import trackingActions from './actions';
import { trackEvent } from 'qollap-tracking';


export function* trackModuleOpened(action) {
  if (!rootProps.lmsPreview) {
    const curModuleAttempt = yield select(getCurrentModuleAttempt);

    if (!curModuleAttempt || curModuleAttempt.moduleUuid !== action.uuid) {
      yield trackEvent(
        'module_opened',
        {
          uuid: action.uuid
        },
        false
      );
      yield put(trackingActions.storeModuleAttempt(action.uuid));
    }
  }
  yield null;
}

export function* trackModuleFinished(action) {
  if (!rootProps.lmsPreview) {
    const curModuleAttempt = yield select(getCurrentModuleAttempt);

    if (curModuleAttempt && curModuleAttempt.moduleUuid === action.uuid) {
      yield trackEvent(
        'module_finished',
        {
          uuid: action.uuid
        },
        false
      );
      yield put(trackingActions.clearModuleAttempt());
    }
  }
  yield null;
}

function* trackEveryModuleOpenedSaga() {
  yield takeLatest(TYPES.MODULE_OPENED, trackModuleOpened);
}

function* trackEveryModueFinishedSaga() {
  yield takeLatest(TYPES.MODULE_FINISHED, trackModuleOpened);
}

export default function* trackingSaga() {
  yield all([
    trackEveryModuleOpenedSaga(),
    trackEveryModueFinishedSaga(),
  ]);
}

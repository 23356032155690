import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from '../../utils';
import classNames from 'classnames';
import { fetchNotifications, seenAllNotifications } from '../../store/app/actions';

import Header from '../../components/header';
import './styles.css';

class Notifications extends React.Component {

  fetchNotifications() {
    this.props.fetchNotifications();
  }

  seenAllNotifications() {
    this.props.seenAllNotifications();
  }

  componentDidMount() {
    this.fetchNotifications();
  }

  componentWillUnmount() {
    this.seenAllNotifications();
  }

  render() {
    const { notifications } = this.props;

    return (
      <section className={classNames({ 's-notifications': true, 'default-view-transition': true })}>
        <Header className="header" hamburger logo />

        <section className="container">
          <h1>{translate('NOTIFICATIONS_TITLE')}</h1>

          {notifications && notifications.length > 0
            ? notifications.map((notification, index) =>
              <div key={index} className="notification">
                <div className="notification__bullet" style={{background: notification.unseen ? '#FF8000' : '#366878'}}></div>
                <div className="notification__text">
                  {notification.data && <h3 className="notification__title">{notification.data.title}</h3>}
                  <p className="notification__body" dangerouslySetInnerHTML={{__html: notification.message }}></p>
                  <p className="notification__date">{new Date(notification.added).toLocaleDateString()}</p>
                </div>
              </div>
            )
            : <p className="notification__body">There are no notifications.</p>
          }

        </section>
      </section>
    );
  }
}

Notifications.propTypes = {
  fetchNotifications: PropTypes.func,
  seenAllNotifications: PropTypes.func,
  notifications: PropTypes.array,
};

const mapStateToProps = state => {
  const notifications = state.notifications && state.notifications.results;
  return {
    notifications,
  };

};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchNotifications,
  seenAllNotifications,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

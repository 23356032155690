import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { translate } from '../../utils';
import Button from '../../components/button';

const IntroSliderNext = props => {
  const { slideCount, currentSlide } = props;
  return slideCount - 1 !== currentSlide ? (
    <Button className="next-button" text={translate('INTRO_GO')} />
  ) : null;
};

class IntroSlider extends React.Component {
  continue() {
    (this.props.firstVisit || this.props.firstVisit === undefined) ? this.props.history.push('/intake') : this.props.history.goBack();
  }

  nextSlide() {
    this.slider.slickNext();
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      swipeToSlide: true,
      slidesToScroll: 1,
      initialSlide: 0,
      ltr: true,
      arrows: false,
      nextArrow: <IntroSliderNext />,
      customPaging: () => (<div>  </div>),
    };

    const { slides, mediaUrl, firstName } = this.props;

    return (
      <div>
        <Slider ref={c => (this.slider = c)} {...settings}>
          {slides.map((slide, index) =>
            <div className="intro-slide" key={index}>
              <div className="slide-container">
                {slide.image.image &&
                  <div className="intro-img-container">
                    <img className="intro-image" src={mediaUrl + slide.image.image} />
                  </div>
                }
                <div className="intro-content">
                  {(index === 0 && firstName)
                    ? <h3 className="intro-title">Hi, {firstName}!</h3>
                    : <h3 className="intro-title">{slide.title}</h3>
                  }
                  <h2 className="intro-txt">{slide.text}</h2>
                  <Button className="intro-button" text={translate('INTRO_NEXT')} onClick={index === (slides.length - 1) ? this.continue.bind(this) : this.nextSlide.bind(this)} />
                </div>
              </div>
            </div>
          )}
        </Slider>
      </div>
    );
  }
}

IntroSlider.propTypes = {
  history: PropTypes.object,
  firstName: PropTypes.string,
  mediaUrl: PropTypes.string,
  slides: PropTypes.array,
  firstVisit: PropTypes.bool
};

const mapStateToProps = state => {
  const user = state.userData.profile_flexible_field;

  return {
    firstVisit: user && user.first_visit
  };
};

export default connect(mapStateToProps)(IntroSlider);

import * as TYPES from './types';


const randomTodo = (state = {}, action) => {
  let payload;

  switch (action.type) {
    case TYPES.CREATE_RANDOM_TODO:
      payload = action.payload;
      // go through all the journey and filter out all the uncompleted and locked modules; store them in an array.
      payload = payload.reduce((acc, cur) =>
        [...acc, ...cur.modules.filter(module => (module.completed !== 100 && !module.locked))]
      , []);
      // return random module or if all modules are completed then return empty object.
      payload = (payload[Math.floor(Math.random() * payload.length)] || {});
      return payload;
  }

  return state;
};

const journeys = (state = [], action) => {
  let payload;

  switch (action.type) {
    case TYPES.FETCH_JOURNEYS_SUCCESS:
      payload = action.payload;
      payload.map((journey, i) => {
        // check completed modules for every journey and add a progress prop.
        const modules = journey.modules;
        if (modules.length > 0) {
          const progress = (modules.filter(module => module.completed == 100).length) / modules.length * 100;
          journey.progress = progress;
        }

        // if not first journey and first journey is not completed then add locked prop to the journey and his modules.
        if (i !== 0 && payload[0].progress !== 100) {
          journey.locked = true;
          journey.modules = journey.modules.map(module => ({ ...module, locked: true }));
        }

        return journey;
      });
      return payload;
  }

  return state;
};

const magicWall = (state = {}, action) => {
  let payload;
  let modules;

  switch (action.type) {
    case TYPES.GENERATE_MAGIC_WALL_SUCCESS:
      payload = action.payload.pages;
      modules = action.payload.modules;

      payload = payload.filter(page => page.title == 'magic wall')[0].content.magic_wall;
      payload = payload.reduce((out, slide, i) => {
        if (slide.type == 'question') {
          const updatedSlides = [
            ...out,
            {
              ...slide,
              responses: slide.responses.map(response => ({
                ...response,
                module: modules.filter(x => x.uuid == response.module)[0]
              }))
            }
          ];

          const available = updatedSlides[i] && updatedSlides[i].responses
            .map(response => response.module)
            .every(module => (module && module.completed !== 100 && !module.locked));

          return available ? updatedSlides : [...out];
        } else {
          return [...out, slide];
        }
      }, []);

      payload = payload[Math.floor(Math.random()*payload.length)];
      return payload;
  }

  return state;
};

export default {
  randomTodo,
  journeys,
  magicWall,
};

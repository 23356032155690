import React from 'react';
import { translate } from '../../utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {clearHelpNeeded, fetchReport} from '../../store/app/actions';

import { IconSmileSad, IconSmileNeutral, IconSmileHappy, IconQuestion } from '../../components/icons';
import Header from '../../components/header';
import ReportingGoal from './reporting_goal';
import Loader from '../../components/loader';
import './styles.css';

class ReportingDetail extends React.Component {
  state = {
    employee: null,
    journeyInfo: [],
    needs: [],
    loading: true
  };

  // calculate overall progress based on module completion.
  calculateOverallProgress = () => {
    const modules = this.state.employee.invitations;
    const allModules = modules.length;
    const completedModules = modules.filter(mod => mod.completed === true);
    const progress = completedModules.length / allModules * 100;
    this.setState((prevState) => ({
      employee: Object.assign(prevState.employee, {progress})
    }));
  }

  fetchModules = (journey) => {
    const allModules = this.state.employee.invitations;
    // fetch matching section based on journey pk
    const matchingSection = this.props.sections && this.props.sections.filter(section => section.parent === journey.pk);
    const matchingSectionPk = matchingSection[0].pk;

    // filter modules by section pk number.
    const journeyModulesArray = allModules.filter(mod => mod.containers[0] === matchingSectionPk);
    // sort modules alphabetically by name.
    const journeyModulesSorted = journeyModulesArray.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    return journeyModulesSorted;
  }

  calculateJourneyProgress(journey) {
    const journeyModulesArray = this.fetchModules(journey);
    const journeyTotalModules = journeyModulesArray.length;

    // calculate amount of completed modules.
    const journeyCompletedModules = journeyModulesArray.filter(mod => mod.completed === true).length;

    const journeyProgress = journeyCompletedModules / journeyTotalModules * 100;
    return journeyProgress;
  }

  fetchJourneyInfo = () => {
    // create object array with info for each journey.
    const journeys = this.props.journeys;
    const journeyInfo = journeys.map((journey) => ({
      title: journey.title,
      pk: journey.pk,
      progress: this.calculateJourneyProgress(journey),
      modules: this.fetchModules(journey),
    }));

    this.setState(() => ({ journeyInfo }));
  }

  fetchNeeds = () => {
    const whatsupstring = this.state.employee.flexible_field.whatsup;

    if(whatsupstring) {
      const whatsupObject = whatsupstring && JSON.parse(whatsupstring);
      const whatsupArray = whatsupObject && Object.values(whatsupObject);
      const whatsupWithNeedsArray = whatsupArray.filter(whatsup => whatsup.needs);
      const whatsupNeeds = whatsupWithNeedsArray.map(whatsup => ({
        date: new Date(Object.keys(whatsup.needs)[0]),
        need: Object.values(whatsup.needs)[0]
      })).sort((a, b) => a.date > b.date ? 1 : 0);
      this.setState(() => ({ needs: whatsupNeeds }));
    }
  }

  fetchLatestWhatsup = () => {
    const whatsupstring = this.state.employee.flexible_field.whatsup;

    if(whatsupstring) {
      const whatsupObject = whatsupstring && JSON.parse(whatsupstring);
      const whatsupArray = whatsupObject && Object.values(whatsupObject);
      const latestWhatsup = whatsupArray[whatsupArray.length -1];
      // Create a copy of latestWhatsup with only the subquestions
      const latestMoodQuestions = JSON.parse(JSON.stringify(latestWhatsup));
      const notIncluded = ['needs', 'overall'];
      Object.keys(latestMoodQuestions)
        .filter(key => notIncluded.includes(key))
        .forEach(key => delete latestMoodQuestions[key]);
      const latestMoodQuestionsArray = Object.values(latestMoodQuestions);
      this.setState(() => ({
        latestWhatsup,
        latestMoodQuestionsArray,
      }));
    }
  }

  displayEmployeeInfo = async () => {
    const displayedEmployee = this.props.employees.filter(employee => employee.pk == this.props.match.params.userId)[0];
    await this.setState({ employee: displayedEmployee, loading: false });
    this.calculateOverallProgress();
    this.fetchLatestWhatsup();
    this.fetchNeeds();
    this.fetchJourneyInfo();
  }

  async componentDidMount() {
    if(!(this.props.employees || []).length){
      this.props.fetchReport();
    }
    else if(this.props.employees) {
      this.displayEmployeeInfo();
    }
  }
  componentWillUnmount() {
    this.props.clearHelpNeeded(this.props.match.params.userId);
  }

  async componentDidUpdate(prevProps) {
    // Once reporting data is loaded, save correct employee data to sate
    if (prevProps.employees !== this.props.employees) {
      this.displayEmployeeInfo();
    }
  }

  render() {
    const { employee, needs, latestWhatsup, latestMoodQuestionsArray, journeyInfo, loading } = this.state;
    if(!employee) {
      return null
    }
    return (
      <section className={classNames({ 's-reporting': true, 'default-view-transition': true })}>
        <Header className="header header--orange" hamburger logo />

        <section className="s-reporting-detail">

          <section className="user-info">
            <svg className="user-info__bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
              <polygon fill="white" points="0,100 100,85 100,0 100,100"/>
            </svg>

            <div className="user-info__container">
              {employee &&
               <>
                  <div className="user-info__content">
                    <div className="user-info__img-container">
                      <img className="user-info__img" src={employee.image} alt="Employee profile photo" />
                    </div>
                    {(latestWhatsup && latestWhatsup.overall === 'sad') && <IconSmileSad className="user-info__mood" />}
                    {(latestWhatsup && latestWhatsup.overall === 'neutral') && <IconSmileNeutral className="user-info__mood" />}
                    {(latestWhatsup && latestWhatsup.overall === 'happy') && <IconSmileHappy className="user-info__mood" />}

                    <h1 className="user-info__name">{employee.name}</h1>
                    <p className="user-info__function">{employee.subtitle}</p>
                  </div>
                  <div className="progress-bar">
                    <div
                      className="progress-fill"
                      style={{ width: `${employee.progress}%` }}>
                    </div>
                  </div>
                </>
              }

            </div>
          </section>

          <div className="bottom-container">

            {needs.length > 0 &&
              <section className="help-requests">
                <h1>{translate('REPORTING_HELP_REQUESTS')}</h1>
                {needs.reverse().map((need, index) => (
                  <div key={index} className="help-requests__container">
                    <IconQuestion className="help-requests__icon" />
                    <div className="help-requests__text">
                      <p className="help-requests__date">{need.date.toLocaleDateString()}</p>
                      <p className="help-requests__request">{need.need}</p>
                    </div>
                  </div>
                ))}
              </section>
            }

            {latestMoodQuestionsArray &&
              <section className="mood-overview">
                <h1>{translate('REPORTING_CURRENT_MOOD')}</h1>
                {latestMoodQuestionsArray.map((question, index) =>
                  <div key={index} className="mood">
                    <div className="mood__txt">
                      <p className="mood__goal">{question.goal_title}</p>
                      <p className="mood__question">{question.question_txt}</p>
                    </div>
                    {question.answer === 'happy' && <IconSmileHappy />}
                    {question.answer === 'neutral' && <IconSmileNeutral />}
                    {question.answer === 'sad' && <IconSmileSad />}
                  </div>
                )}
              </section>
            }

            <section className="goal-overview">
              <h1>{translate('REPORTING_GOAL_OVERVIEW')}</h1>
              {journeyInfo && journeyInfo.map((journey, index) =>
                <ReportingGoal
                  key = {index}
                  title = {journey.title}
                  modules = {journey.modules}
                  progress = {journey.progress}
                />
              )}
            </section>

          </div>
        </section>
        <Loader text={translate('APP_LOADER_MSG')} loading={loading} />
      </section>
    )
  }
}

ReportingDetail.propTypes = {
  questionnairesArray: PropTypes.array,
  fetchReport: PropTypes.func,
  employees: PropTypes.array,
  containers: PropTypes.array,
  journeys: PropTypes.array,
  sections: PropTypes.array,
  moodQuestionsArray: PropTypes.array,
};

const mapStateToProps = state => {
  const whatsup = state.pages.filter(page => page.title === 'whatsup questionnaire')[0];
  const questionnairesObject = whatsup && whatsup.content;
  const employees = state.report && state.report.users;
  const containers = state.report && state.report.containers;
  const journeys = containers && containers.filter(container => !container.parent);
  const sections = containers && containers.filter(container => container.parent);

  return {
    questionnairesArray: questionnairesObject && Object.values(questionnairesObject),
    employees,
    containers,
    journeys,
    sections,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReport,
  clearHelpNeeded
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReportingDetail);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { contentToComponent } from '../method-converter';
import method from '../work-methods';


class TemplateBackgroundArticle extends React.Component {
  state = {}

  componentDidMount() {
    this.props.next();
  }

  readMore() {
    this.setState({ active: true });
  }

  render() {
    const { template, data } = this.props;
    const { active } = this.state;
    const classes = ['s-module-section', `t-${template}`];
    const methods = data.children.map(method => method);
    const components = methods.map(contentToComponent);
    const text = this.state.active ? data.text : `${data.text.substring(0, 100)}...`;

    return (
      <section className={classes.join(' ')}>
        <main className="container">
          <div className="inner-container">
            <div className="article">
              <div className="article__intro">
                { data.image && <method.LMSImage src={data.image} /> }
                <method.LMSHeading title={data.heading} />
                <method.LMSText text={text} />
                {!active && <button className="article__btn" onClick={this.readMore.bind(this)}>{'Verder lezen'}</button>}
              </div>
              <div className={classnames({'article__content': true, 'state-active': active})}>{components}</div>
            </div>
          </div>
        </main>
      </section>
    )
  }
}

TemplateBackgroundArticle.propTypes = {
  template: PropTypes.string,
  data: PropTypes.object,
  next: PropTypes.func,
};

export default TemplateBackgroundArticle;

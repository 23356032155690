import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { translate } from '../../utils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, fetchNotifications } from '../../store/app/actions';

import { IconCross } from '../icons';
import './styles.css';

class Nav extends React.Component {

  fetchNotifications() {
    this.props.fetchNotifications();
  }

  componentDidMount() {
    this.fetchNotifications();
  }

  render() {
    const { active, toggle, logout, todos, unreadNotifications, user } = this.props;
    return (
      <>
        <div className={classnames({ 'overlay': true, 'active': active })} onClick={toggle}></div>

        <div className={classnames({ 'menu': true, 'active': active })}>
          <div className="menu__close" onClick={toggle}>
            <IconCross />
          </div>

          <div className="menu__items">
            <NavLink activeClassName="active" exact to="/" className="menu__item">
              {translate('NAV_DASHBOARD')}
            </NavLink>
            <NavLink activeClassName="active" exact to="/notifications" className="menu__item">
              {translate('NAV_NOTIFICATIONS')}
              {unreadNotifications > 0 && <span className="menu__notification">{unreadNotifications}</span>}
            </NavLink>
            <NavLink activeClassName="active" exact to="/todos" className="menu__item">
              {translate('NAV_TODOS')}
              {todos && <span className="menu__notification">{todos}</span>}
            </NavLink>
            <NavLink activeClassName="active" exact to="/whoiswho" className="menu__item">
              {translate('NAV_WHO_IS_WHO')}
            </NavLink>
            {(user.profile_flexible_field && user.profile_flexible_field.is_manager) &&
              <NavLink activeClassName="active" exact to="/reporting" className="menu__item">
                {translate('NAV_MANAGER_DASHBOARD')}
              </NavLink>
            }
            <NavLink activeClassName="active" exact to="/settings" className="menu__item">
              {translate('NAV_SETTINGS')}
            </NavLink>
            <NavLink activeClassName="active" exact to="/faq" className="menu__item">
              {translate('NAV_FAQS')}
            </NavLink>
            <NavLink activeClassName="active" exact to="/feedback" className="menu__item">
              {translate('NAV_FEEDBACK')}
            </NavLink>
          </div>

          <button className="menu__logout" onClick={logout}>{translate('NAV_LOGOUT')}</button>
        </div>
      </>
    );
  }
}

Nav.propTypes = {
  active: PropTypes.bool,
  toggle: PropTypes.func,
  logout: PropTypes.func,
  unreadNotifications: PropTypes.number,
  todos: PropTypes.number,
  user: PropTypes.object,
  fetchNotifications: PropTypes.func,
};

const mapStateToProps = state => {
  const todos = (state.todos || []).filter(todo => !todo.completed_at);
  const user = state.userData;
  const notifications = state.notifications && state.notifications.results;
  const unreadNotifications = notifications && notifications.filter(notification => notification.unseen).length;

  return {
    todos: todos.length > 0 ? todos.length : null,
    user,
    unreadNotifications,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logout,
  fetchNotifications,
}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Nav);

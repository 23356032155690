import { all, put, select, takeLatest } from 'redux-saga/effects';
import { replace, push } from 'connected-react-router';

import * as TYPES from './types';
import { rootProps } from '../../root';
import { getApi } from '../../api';
import { fetchModulesSaga } from '../module_player/sagas';
import { fetchJourneys } from '../dashboard/sagas';
import { fetchTodos } from '../progress/sagas';
import {getNotificationSettings} from "../user/sagas";
import { initializeTracker } from 'qollap-tracking';


function* checkAuthenticated() {
  const api = getApi();
  if (!api.authenticated) {
    yield put({ type: TYPES.USER_AUTHENTICATED_FAILED });
  } else {
    yield put({ type: TYPES.USER_AUTHENTICATED_SUCCESS });
  }
}

export function* Register(data) {
  const api = getApi();
  try {
    const response = yield api.postRegister(data.payload);
    yield put({ type: TYPES.POST_REGISTER_SUCCESS, payload: response });
    yield put(replace('/auth/login'));
  } catch (error) {
    yield put({ type: TYPES.POST_REGISTER_FAILED, error });
  }
}

function* login({ payload }) {
  const api = getApi();
  try {
    yield api.postLogin(payload);
    yield put({ type: TYPES.USER_AUTHENTICATED_SUCCESS, payload, login: true });
  } catch (error) {
    yield put({ type: TYPES.USER_AUTHENTICATED_FAILED, error });
  }
}

export function* logout() {
  try {
    yield localStorage.removeItem('token');
    yield put(push('/auth/login'));
    yield put({ type: TYPES.LOGOUT_SUCCESS });
  } catch ({ message }) {
    yield put({ type: TYPES.LOGOUT_FAILED });
  }
}

export function* resetPassword({ email }) {
  const api = getApi();
  try {
    yield api.resetPassword(email);
    yield put({ type: TYPES.RESET_PASSWORD_SUCCESS, payload: email });
  } catch (error) {
    yield put({ type: TYPES.RESET_PASSWORD_FAILED, error });
  }
}

export function* confirmPassword({payload, register}) {
  const api = getApi();
  try {
    yield api.confirmPassword(payload);
    yield put(replace('/'));
    yield put({ type: TYPES.CONFIRM_PASSWORD_SUCCESS, payload: {...payload, register} });
  } catch (error) {
    yield put({ type: TYPES.CONFIRM_PASSWORD_FAILED, error });
  }
}

export function* fetchInvite(data) {
  const api = getApi();
  try {
    const response = yield api.fetchInvite(data.payload);
    yield put({ type: TYPES.FETCH_INVITE_SUCCESS, payload: {...data.payload, ...response} });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_INVITE_FAILED, payload: { message } });
  }
}

export function* fetchReport() {
  const api = getApi();
  try {
    const response = yield api.fetchReport();
    yield put({ type: TYPES.FETCH_REPORT_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_REPORT_FAILED, payload: { message } });
  }
}

export function* fetchColleagues() {
  const api = getApi();
  try {
    const response = yield api.fetchColleagues({image: 1});
    yield put({ type: TYPES.FETCH_COLLEAGUES_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_COLLEAGUES_FAILED, payload: { message } });
  }
}

export function* checkIntro(fromLogin) {
  const firstVisit = yield select(state => state.firstVisit);
  // Skip intro when the user is a manager instead of a new hire
  const startDate = yield select(state => state.userData.profile_flexible_field && state.userData.profile_flexible_field.start_date);
  if (firstVisit === true && startDate && !rootProps.lmsPreview) {
    yield put(replace('/intro'));
  } else if (fromLogin) {
    yield put(push('/'));
  }
}

function* updateIntro(action) {
  yield updateFlexibleFields(action);
  yield put(replace('/'));
}

export function* fetchFlexibleFields() {
  const api = getApi();
  try {
    const response = yield api.fetchFlexFields();
    yield put({ type: TYPES.FETCH_FLEXIBLE_FIELDS_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_FLEXIBLE_FIELDS_FAILED, payload: { message } });
  }
}

export function* updateFlexibleFields({ type, payload }) {
  const api = getApi();
  try {
    yield api.postFlexFields(payload);
    yield put({ type: TYPES.POST_FLEXIBLE_FIELDS_SUCCESS });
  } catch ({ message }) {
    yield put({ type: TYPES.POST_FLEXIBLE_FIELDS_FAILED, payload: { message } });
  }
}

export function* updateWhatsup({type, payload}) {
  const api = getApi();
  yield api.whatsupNotify();
  yield updateFlexibleFields({payload})
}

export function* clearHelpNeeded({type, payload}) {
  const api = getApi()
  const response = yield api.clearHelpNeeded(payload.userId);
  if(response.message !== "Nothing todo") {
    yield put({type: TYPES.FETCH_REPORT})
  }

}

export function* fetchPages() {
  const api = getApi();
  try {
    const response = yield api.fetchPages();
    yield put({ type: TYPES.FETCH_PAGES_SUCCESS, payload: response })
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_PAGES_FAILED, payload: { message } })
  }
}

export function* fetchUserData() {
  const api = getApi();
  try {
    const response = yield api.fetchUserData();
    yield put({ type: TYPES.FETCH_USER_DATA_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_USER_DATA_FAILED, payload: { message } });
  }
}

function* changeLanguage({ type, payload }) {
  const api = getApi();
  try {
    const response = yield api.changeLanguage(payload.language);
    yield put({ type: TYPES.FETCH_USER_DATA_SUCCESS, payload: response });
    yield fetchModulesSaga()
    yield fetchTranslations()
  } catch ({ message }) { }
}

export function* fetchTranslations() {
  const api = getApi();
  try {
    const response = yield api.fetchTranslations();
    yield put({ type: TYPES.FETCH_TRANSLATIONS_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_TRANSLATIONS_FAILED, payload: { message } });
  }
}

export function* sendUserFeedback({payload}) {
  const api = getApi();
  try {
    yield api.sendFeedback(payload.message);
    yield put({ type: TYPES.SEND_USER_FEEDBACK_SUCCESS });
  } catch ({ message }) {
    yield put({ type: TYPES.SEND_USER_FEEDBACK_FAILED, message });
  }
}

export function* fetchNotifications() {
  const api = getApi();
  try {
    const response = yield api.fetchNotifications({limit: 20});
    yield put({ type: TYPES.FETCH_NOTIFICATIONS_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_NOTIFICATIONS_FAILED, message });
  }
}

export function* seenAllNotifications() {
  const api = getApi();
  try {
    yield api.seenAllNotifications();
    yield put({ type: TYPES.SEEN_ALL_NOTIFICATIONS_SUCCESS });
  } catch ({ message }) {
    yield put({ type: TYPES.SEEN_ALL_NOTIFICATIONS_FAILED, message });
  }
}

export function* initAfterAuth({login}) {
  const qApi = getApi().apiPromise;
  initializeTracker({
    api: qApi,
    appName: 'takeaway',
    collectInterval: 60000,
    minAfkTime: 60
  });
  yield all([
    fetchPages(),
    fetchJourneys(),
    fetchModulesSaga(),
    fetchTodos(),
    fetchUserData(),
    fetchFlexibleFields(),
  ]);
  yield getNotificationSettings();
  yield checkIntro(login);
}

// Listeners.
function* changeLanguageListener() {
  yield takeLatest(TYPES.CHANGE_LANGUAGE, changeLanguage);
}

function* fetchUserDataListener() {
  yield takeLatest(TYPES.FETCH_USER_DATA, fetchUserData)
}

function* updateIntroListener() {
  yield takeLatest(TYPES.UPDATE_INTRO, updateIntro);
}

function* registerListener() {
  yield takeLatest(TYPES.POST_REGISTER, Register);
}

function* fetchInviteListener() {
  yield takeLatest(TYPES.FETCH_INVITE, fetchInvite);
}

function* fetchReportListener() {
  yield takeLatest(TYPES.FETCH_REPORT, fetchReport);
}

function* fetchColleaguesListener() {
  yield takeLatest(TYPES.FETCH_COLLEAGUES, fetchColleagues);
}

function* loginListener() {
  yield takeLatest(TYPES.POST_LOGIN, login);
}

function* logoutListener() {
  yield takeLatest(TYPES.LOGOUT, logout);
}

function* resetPasswordListener() {
  yield takeLatest(TYPES.RESET_PASSWORD, resetPassword);
}

function* confirmPasswordListener() {
  yield takeLatest(TYPES.CONFIRM_PASSWORD, confirmPassword);
}

function* sendUserFeedbackListener() {
  yield takeLatest(TYPES.SEND_USER_FEEDBACK, sendUserFeedback);
}

function* updateFlexibleFieldsListener() {
  yield takeLatest(TYPES.POST_FLEXIBLE_FIELDS, updateFlexibleFields);
}

function* userAuthListener() {
  yield takeLatest(TYPES.USER_AUTHENTICATED_SUCCESS, initAfterAuth);
}

function* fetchNotificationsListener() {
  yield takeLatest(TYPES.FETCH_NOTIFICATIONS, fetchNotifications);
}

function* seenAllNotificationsListener() {
  yield takeLatest(TYPES.SEEN_ALL_NOTIFICATIONS, seenAllNotifications);
}


export default function* appSaga() {
  yield all([
    takeLatest(TYPES.POST_WHATSUP, updateWhatsup),
    takeLatest(TYPES.CLEAR_HELP_NEEDED, clearHelpNeeded),
    checkAuthenticated(),
    fetchTranslations(),
    registerListener(),
    fetchInviteListener(),
    updateIntroListener(),
    fetchUserDataListener(),
    changeLanguageListener(),
    fetchNotificationsListener(),
    seenAllNotificationsListener(),
    loginListener(),
    logoutListener(),
    resetPasswordListener(),
    confirmPasswordListener(),
    sendUserFeedbackListener(),
    updateFlexibleFieldsListener(),
    userAuthListener(),
    fetchReportListener(),
    fetchColleaguesListener(),
  ]);
}

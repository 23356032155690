import * as TYPES from './types';


const todos = (state = [], action) => {
  switch (action.type) {
    case TYPES.FETCH_TODOS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};


export default {
  todos
};

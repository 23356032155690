export const POST_ANSWER = 'POST_ANSWER';
export const POST_ANSWER_SUCCESS = 'POST_ANSWER_SUCCESS';
export const POST_ANSWER_FAILED = 'POST_ANSWER_FAILED';

export const POST_ANSWER_DATA_SUCCESS = 'POST_ANSWER_DATA_SUCCESS';
export const POST_ANSWER_DATA_FAILED = 'POST_ANSWER_DATA_FAILED';

export const ANSWER_FETCHED = 'ANSWER_FETCHED';

export const ANSWER_UPDATE = 'ANSWER_UPDATE';
export const ANSWER_SELECT = 'ANSWER_SELECT';

export const RESET_QUESTION = 'RESET_QUESTION';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILED = 'FETCH_USER_DATA_FAILED';

export const FETCH_MODULE_EXCERPT_SUCCESS = 'FETCH_MODULE_EXCERPT_SUCCESS';
export const FETCH_MODULE_EXCERPT_FAILED = 'FETCH_MODULE_EXCERPT_FAILED';

export const COMPLETE_MODULE = 'COMPLETE_MODULE';
export const COMPLETE_MODULE_SUCCESS = 'COMPLETE_MODULE_SUCCESS';
export const COMPLETE_MODULE_FAILED = 'COMPLETE_MODULE_FAILED';

export const MODULE_TO_BE_COMPLETED = 'MODULE_TO_BE_COMPLETED';

export const FETCH_MODULES = 'FETCH_MODULES';
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS';
export const FETCH_MODULES_FAILED = 'FETCH_MODULES_FAILED';

export const FETCH_MODULE = 'FETCH_MODULE';
export const FETCH_MODULE_SUCCESS = 'FETCH_MODULE_SUCCESS';
export const FETCH_MODULE_FAILED = 'FETCH_MODULE_FAILED';

export const FETCH_MODULE_CONTENT = 'FETCH_MODULE_CONTENT';
export const FETCH_MODULE_CONTENT_SUCCESS = 'FETCH_MODULE_CONTENT_SUCCESS';
export const FETCH_MODULE_CONTENT_FAILED = 'FETCH_MODULE_CONTENT_FAILED';

export const FETCH_QUESTION_RESULTS_SUCCESS = 'FETCH_QUESTION_RESULTS_SUCCESS';
export const FETCH_QUESTION_RESULTS_FAILED = 'FETCH_QUESTION_RESULTS_FAILED';

export const FETCH_ANSWER = 'FETCH_ANSWER';

export const PREFILL_ALL_ANSWERS = 'PREFILL_ALL_ANSWERS';
export const PREFILL_ALL_ANSWERS_SUCCESS = 'PREFILL_ALL_ANSWERS_SUCCESS';
export const PREFILL_ALL_ANSWERS_FAILED = 'PREFILL_ALL_ANSWERS_FAILED';

export const CLEAR_ALL_ANSWERS = 'CLEAR_ALL_ANSWERS';
export const CLEAR_ALL_ANSWERS_SUCCESS = 'CLEAR_ALL_ANSWERS_SUCCESS';
export const CLEAR_ALL_ANSWERS_FAILED = 'CLEAR_ALL_ANSWERS_FAILED';

export const MODULE_ACTIVE = 'MODULE_ACTIVE';
export const MODULE_ACTIVE_SUCCESS = 'MODULE_ACTIVE_SUCCESS';
export const MODULE_ACTIVE_FAILED = 'MODULE_ACTIVE_FAILED';

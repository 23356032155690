import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from '../../utils';
import { Link } from 'react-router-dom';

import { changeUserSettings, getNotificationSettings, updateNotificationSettings } from '../../store/user/actions';
import classNames from 'classnames';
import Header from '../../components/header';
import { IconPencil, IconProfile } from '../../components/icons';
import './styles.css';

class EditProfile extends React.Component {
  state = {
    emailNotificationSetting: this.props.emailNotificationSetting && this.props.emailNotificationSetting.send
  }

  toggleEmail() {
    this.setState((prevState) => {
      return {
        emailNotificationSetting: !prevState.emailNotificationSetting
      };
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.emailNotificationSetting !== prevProps.emailNotificationSetting)  {
      this.setState({emailNotificationSetting: this.props.emailNotificationSetting.send})
    }
  }

  save() {
    let data= {};

    if (this.state.image) {
      data.image = this.state.image;
    }
    if (this.state.dish) {
      data.profile_flexible_field = {
        ...data.profile_flexible_field,
        favourite_dish: this.state.dish
      };
    }
    if (this.state.emailNotificationSetting !== this.props.emailNotificationSetting.send) {
      this.props.updateNotificationSettings({pk: this.props.emailNotificationSetting.pk, send: this.state.emailNotificationSetting})
    }

    this.props.changeUserSettings(data);

    // TO DO - update email preferences when ID is known.
    // this.props.updateNotificationSettings();
  }

  changePhoto(event) {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ image: reader.result });
    };
    event.target.files[0] && reader.readAsDataURL(event.target.files[0]);
  }

  render() {
    const { username, profileImageURL, jobtitle, favourite_dish } = this.props;
    const { emailNotificationSetting, image } = this.state;

    return (
      <section className="s-settings">
        <Header className="header" hamburger logo />

        <section className="container">
          <h1>{translate('SETTINGS_TITLE')}</h1>

          <div className="s-settings__email">
            <p>Email notifications on/off</p>
            <span
              className={classNames({ 'toggle': true, 'state-active': emailNotificationSetting })}
              onClick={this.toggleEmail.bind(this)}
            >
              <span className="toggle__switch"></span>
            </span>
          </div>

          <div className="s-settings__profile">
            <h1>{translate('SETTINGS_PROFILE_TITLE')}</h1>

            <div className="s-settings__primary-info">
              <div className="s-settings__profile-img">
                <div className="s-settings__img-container">
                  {(image || profileImageURL) ?
                    <img src={image ? image : profileImageURL} />
                    : <IconProfile />
                  }
                </div>
                <span className="s-settings__edit-button">
                  <input type="file" name="image" onChange={this.changePhoto.bind(this)} />
                  <IconPencil />
                </span>
              </div>
              <div className="s-settings__text-container">
                <h3 className="s-settings__name">{username}</h3>
                <p className="s-settings__jobtitle">{jobtitle}</p>
              </div>
            </div>

            <div className="s-settings__secondary-info">
              {/* {location &&
                <div className="s-settings__item">
                  <p className="s-settings__label">{translate('SETTINGS_LOCATION')}</p>
                  <p className="s-settings__input">{location}</p>
                </div>
              } */}
              <div className="s-settings__item">
                <p className="s-settings__label">{translate('SETTINGS_DISH')}</p>
                <input
                  type="text"
                  name="dish"
                  className="s-settings__field c-form-field__input"
                  defaultValue={favourite_dish}
                  onChange={(e) => { this.setState({ dish: e.target.value }); }}
                  placeholder={translate('YOUR_FAVOURITE_DISH')}
                />
              </div>
              {/* {startDate &&
                <div className="s-settings__item">
                  <p className="s-settings__label">{translate('SETTINGS_START_DATE')}</p>
                  <p className="s-settings__input">{startDate}</p>
                </div>
              } */}
            </div>
          </div>

          <Link to="/settings" exact className="c-button" onClick={this.save.bind(this)}>{translate('PROFILE_SAVE')}</Link>
          <Link className="link" to="/settings">{translate('PROFILE_CANCEL')}</Link>

        </section>
      </section>
    );
  }
}

EditProfile.propTypes = {
  // startDate: PropTypes.string,
  // location: PropTypes.string,
  username: PropTypes.string,
  jobtitle: PropTypes.string,
  email_notifications: PropTypes.bool,
  profileImageURL: PropTypes.string,
  changeUserSettings: PropTypes.func,
  favourite_dish: PropTypes.string,
  getNotificationSettings: PropTypes.func,
  updateNotificationSettings: PropTypes.func,
  settings: PropTypes.array,
};

const mapStateToProps = state => {
  const user = state.userData && state.userData;
  const settings = state.settings && state.settings;

  return {
    profileImageURL: user && user.image,
    // location: user && user.profile_flexible_field.import_data.Location,
    username: user && `${user.user.first_name} ${user.user.last_name}`,
    // startDate: user && new Date(user.profile_flexible_field.start_date).toLocaleDateString(),
    jobtitle: user && user.subtitle,
    favourite_dish: user && user.profile_flexible_field.favourite_dish,
    emailNotificationSetting: state && state.settings && state.settings.find(noticeSetting => noticeSetting.notice_type_display === "eca_add_notification_action_email"),
    settings,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeUserSettings,
  updateNotificationSettings,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);

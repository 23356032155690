export const USER_AUTHENTICATED_SUCCESS = 'USER_AUTHENTICATED_SUCCESS';
export const USER_AUTHENTICATED_FAILED = 'USER_AUTHENTICATED_FAILED';

export const POST_LOGIN = 'POST_LOGIN';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const POST_REGISTER = 'POST_REGISTER';
export const POST_REGISTER_SUCCESS = 'POST_REGISTER_SUCCESS';
export const POST_REGISTER_FAILED = 'POST_REGISTER_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const CONFIRM_PASSWORD_SUCCESS = 'CONFIRM_PASSWORD_SUCCESS';
export const CONFIRM_PASSWORD_FAILED = 'CONFIRM_PASSWORD_FAILED';

export const FETCH_INVITE = 'FETCH_INVITE';
export const FETCH_INVITE_SUCCESS = 'FETCH_INVITE_SUCCESS';
export const FETCH_INVITE_FAILED = 'FETCH_INVITE_FAILED';

export const FETCH_FLEXIBLE_FIELDS_SUCCESS = 'FETCH_FLEXIBLE_FIELDS_SUCCESS';
export const FETCH_FLEXIBLE_FIELDS_FAILED = 'FETCH_FLEXIBLE_FIELDS_FAILED';

export const CLEAR_HELP_NEEDED = 'CLEAR_HELP_NEEDED';

export const POST_WHATSUP = 'POST_WHATSUP';

export const POST_FLEXIBLE_FIELDS = 'POST_FLEXIBLE_FIELDS';
export const POST_FLEXIBLE_FIELDS_SUCCESS = 'POST_FLEXIBLE_FIELDS_SUCCESS';
export const POST_FLEXIBLE_FIELDS_FAILED = 'POST_FLEXIBLE_FIELDS_FAILED';

export const UPDATE_INTRO = 'UPDATE_INTRO';

export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS';
export const FETCH_PAGES_FAILED = 'FETCH_PAGES_FAILED';

export const FETCH_REPORT = 'FETCH_REPORT';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILED = 'FETCH_REPORT_FAILED';

export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILED = 'FETCH_USER_DATA_FAILED';

export const FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_FAILED = 'FETCH_TRANSLATIONS_FAILED';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const SEND_USER_FEEDBACK = 'SEND_USER_FEEDBACK';
export const SEND_USER_FEEDBACK_SUCCESS = 'SEND_USER_FEEDBACK_SUCCESS';
export const SEND_USER_FEEDBACK_FAILED = 'SEND_USER_FEEDBACK_FAILED';

export const CLEAR_UI_FEEDBACK = 'CLEAR_UI_FEEDBACK';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILED = 'FETCH_NOTIFICATIONS_FAILED';

export const SEEN_ALL_NOTIFICATIONS = 'SEEN_ALL_NOTIFICATIONS';
export const SEEN_ALL_NOTIFICATIONS_SUCCESS = 'SEEN_ALL_NOTIFICATIONS_SUCCESS';
export const SEEN_ALL_NOTIFICATIONS_FAILED = 'SEEN_ALL_NOTIFICATIONS_FAILED';

export const FETCH_COLLEAGUES = 'FETCH_COLLEAGUES';
export const FETCH_COLLEAGUES_SUCCESS = 'FETCH_COLLEAGUES_SUCCESS';
export const FETCH_COLLEAGUES_FAILED = 'FETCH_COLLEAGUES_FAILED';

import React from 'react';
import PropTypes from 'prop-types';

import { contentToComponent } from '../method-converter';


class Template extends React.Component {
  componentDidMount() {
    this.props.next();
  }

  render() {
    const { template, data } = this.props;
    const classes = ['s-module-section', `t-${template}`];
    const methods = data.children.map(method => method);
    const components = methods.map(contentToComponent);

    return (
      <section className={classes.join(' ')}>
        <main className="container">
          <div className="inner-container">
            {components}
          </div>
        </main>
      </section>
    )
  }
}

Template.propTypes = {
  template: PropTypes.string,
  data: PropTypes.object,
  next: PropTypes.func,
};

export default Template;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../../../../components/button';
import { IconCheck, IconCross } from '../../../../components/icons';
import { translate } from '../../../../utils';


class Results extends React.Component {
  renderResult(result, index) {
    const { correct, questionText, } = result;
    return (
      <article className={`result ${correct ? 'state-correct' : 'state-incorrect'}`} key={index}>
        <div className="result__text">{questionText}</div>
      </article>
    );
  }

  render() {
    const { data, results, correctAnswers, onRestart, onDone, onCompleted, quizCompleted, ended } = this.props;
    const hideRestartButton = (correctAnswers === results.length);
    return (
      <article className="container">
        <h1 className="heading">{translate('QUIZ_DONE_HEADING')}</h1>
        <p className="text">{quizCompleted ? data.outro_done_heading : translate('QUIZ_LOADING_RESULTS_MSG')}</p>
        {quizCompleted &&
          <React.Fragment>
            <h2 className="score-txt">{translate('QUIZ_SCORE_TEXT')}: {correctAnswers}/{results.length}</h2>
            <section className="results">
              {results.map((result, index) => (
                <div key={index} className="result">
                  <div className="card">
                    <div className="card-top">
                      <p>{result.questionText}</p>
                      <span className={classNames({ 'icon': true, 'state-correct': result.correct, 'state-incorrect': !result.correct })}>
                        {result.correct ? <IconCheck size={10} /> : <IconCross size={9} />}
                      </span>
                    </div>
                    <div className="card-bottom">
                      <p>{translate('QUIZ_CORRECT_ANSWER_TEXT')} {result.correctOptionText}</p>
                    </div>
                  </div>
                </div>
              ))}
            </section>
            <div className="buttons">
              {!hideRestartButton && <Button text={translate('Try again')} onClick={onRestart} />}
              <Button className="outlined" text={translate('Done')} onClick={onCompleted} />
            </div>
          </React.Fragment>
        }
      </article>
    );
  }
}

Results.propTypes = {
  correctAnswers: PropTypes.number,
  data: PropTypes.object,
  results: PropTypes.array,
  onRestart: PropTypes.func,
  fetchAnswers: PropTypes.func,
  onDone: PropTypes.func,
  onCompleted: PropTypes.func,
  quizCompleted: PropTypes.bool,
  ended: PropTypes.bool,
};

export default Results;

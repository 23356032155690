import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { confirmPassword } from '../../store/app/actions';
import { translate } from '../../utils';
import SectionTitle from '../../components/section_title';
import FormField from '../../components/form_field';
import Button from '../../components/button';
import Header from '../../components/header';
import './styles.css';

class ConfirmPassword extends React.Component {
  state = {
    ...this.props.match.params,
    new_password: '',
    new_password_repeat: '',
  };

  handleSubmit(register, e) {
    e.preventDefault();
    this.props.confirmPassword({ ...this.state, register });
    this.setState({ submitted: true });
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { location, errors } = this.props;
    const { submitted } = this.state;
    const register = Boolean(location.pathname.match(/register/g));

    return (
      <div className="s-auth">
        <Header companyLogo logoColor="var(--color-1)" />

        <div className="content-container">
          <SectionTitle
            text={
              register
                ? translate('REGISTER_ACCOUNT')
                : translate('CONFIRM_PASSWORD')
            }
          />
          <p className="description">
            {register
              ? translate('REGISTER_ACCOUNT_CTA')
              : translate('CONFIRM_PASS_CTA')}
          </p>
          <form
            className="form-field-group"
            onSubmit={this.handleSubmit.bind(this, register)}
          >
            <FormField
              errors={submitted && errors && errors.new_password}
              label={translate('NEW_PASSWORD')}
              type="password"
              name="new_password"
              placeholder={translate('New password')}
              value={this.state.new_password}
              onChange={this.handleInputChange.bind(this)}
              required
            />
            <FormField
              errors={submitted && errors && errors.new_password_repeat}
              label={translate('NEW_PASSWORD_REPEAT')}
              type="password"
              name="new_password_repeat"
              placeholder={translate('Repeat new password')}
              value={this.state.new_password_repeat}
              onChange={this.handleInputChange.bind(this)}
              required
            />

            {submitted &&
              errors &&
              errors.non_field_errors &&
              errors.non_field_errors.length > 0 && (
                <div className="error-container">
                  {errors.non_field_errors[0] === 'token not valid' ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: translate(
                          'Your activation link has expired. To receive a new email with a valid activation link, please visit <a href="/#/auth/forgot-pass">forgot password</a>'
                        ),
                      }}
                    />
                  ) : (
                    errors.non_field_errors.map((error, index) => (
                      <p key={index}>{translate(error)}</p>
                    ))
                  )}
                </div>
              )}

            <Button
              text={
                register ? translate('CONTINUE_TO_LOGIN') : translate('Send')
              }
              elem="button"
            />
          </form>
        </div>
      </div>
    );
  }
}

ConfirmPassword.propTypes = {
  errors: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  confirmPassword: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors.confirmPass,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmPassword,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword);

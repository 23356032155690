import React from 'react';
import PropTypes from 'prop-types';

import { translate } from '../../../utils';
import method from '../work-methods';
import LMSInteraction from '../../../components/lms_interaction';
import { IconClockLight, IconCheck , IconGuidance, IconGoal, IconPlus } from '../../../components/icons';


class TemplateIntro extends React.Component {
  state = {};

  componentDidMount() {
    if (this.props.moduleStarted || this.props.moduleCompleted) {
      this.props.next();
    }
  }

  start() {
    this.props.activateModule(this.props.moduleUuid);
    this.props.next();
  }

  addTodo() {
    this.props.addTodo({
      uuid: this.props.moduleUuid,
      title: this.props.moduleTitle,
      mandatory: this.props.module.content.mandatory_module,
      duration: this.props.module.content.duration
    });
  }

  render() {
    const { data, template, moduleStarted, moduleCompleted, todoAdded, module, journey } = this.props;
    const classes = ['s-module-section', `t-${template}`];
    return (
      <section className={classes.join(' ')}>
        <main className="container">
          {data.image && <method.LMSImage className="image" src={data.image} />}
          <div className="inner-container">
            <method.LMSHeading className="heading" title={data.heading} />
            <method.LMSText className="text" text={data.text} />
            <div className="lms-icons">
              <div className="lms-icon">
                <IconGoal />
                {journey && journey.title}
              </div>
              <div className="lms-duration">
                <IconClockLight />
                {module.duration} {translate('MODULE_DURATION_TEXT')}
              </div>
              {module.content.guidance_needed &&
                <div className="lms-guidance">
                  <IconGuidance />
                  {translate('MODULE_GUIDANCE_TEXT')}
                </div>
              }
            </div>
            {!moduleCompleted &&
              <div className="lms-interaction-buttons">
                <LMSInteraction className="start" text={translate('LMS_START')} onClick={this.start.bind(this)} visible disabled={moduleStarted} />
                <LMSInteraction
                  className="todo"
                  text={translate('LMS_ADD_TODO')}
                  onClick={this.addTodo.bind(this)}
                  icon={todoAdded ? <IconCheck color="#0A3847"/> : <IconPlus size="10" />}
                  checked={todoAdded}
                  visible />
              </div>
            }
          </div>
        </main>
      </section>
    );
  }
}

TemplateIntro.propTypes = {
  template: PropTypes.string,
  moduleUuid: PropTypes.string,
  moduleTitle: PropTypes.string,
  moduleStarted: PropTypes.string,
  data: PropTypes.object,
  next: PropTypes.func,
  addTodo: PropTypes.func,
  activateModule: PropTypes.func,
  todoAdded: PropTypes.bool,
  moduleCompleted: PropTypes.bool,
  module: PropTypes.object,
  journey: PropTypes.object,
  history: PropTypes.object,
};

export default TemplateIntro;

import * as TYPES from './types';


export const fetchJourneys = () => ({
  type: TYPES.FETCH_JOURNEYS,
});

export const createRandomTodo = (payload) => {
  return {
    type: TYPES.CREATE_RANDOM_TODO,
    payload
  };
};

export const generateMagicWall = () => {
  return {
    type: TYPES.GENERATE_MAGIC_WALL,
  };
};

export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAILED = 'CHANGE_USER_PASSWORD_FAILED';

export const CHANGE_USER_SETTINGS = 'CHANGE_USER_SETTINGS';
export const CHANGE_USER_SETTINGS_SUCCESS = 'CHANGE_USER_SETTINGS_SUCCESS';
export const CHANGE_USER_SETTINGS_FAILED = 'CHANGE_USER_SETTINGS_FAILED';

export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS';
export const GET_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_NOTIFICATION_SETTINGS_FAILED = 'GET_NOTIFICATION_SETTINGS_FAILED';

export const UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS';
export const UPDATE_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_NOTIFICATION_SETTINGS_SUCCESS';
export const UPDATE_NOTIFICATION_SETTINGS_FAILED = 'UPDATE_NOTIFICATION_SETTINGS_FAILED';

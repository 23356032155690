import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../utils';

import method from '../work-methods';
import LMSInteraction from '../../../components/lms_interaction';


class TemplateOutro extends React.Component {

  componentDidMount() {
    if (this.props.moduleCompleted) {
      this.props.updateAnswer([{
        type: 'OPTION',
        value: this.props.data.multiple_choice.options[0].uuid,
      }]);
    }
  }

  componentWillUnmount() {
    // if answered and module ready for completion;
    // set answer and set module complete.
    if ((this.props.questionData && this.props.questionData.state !== 'ANSWERED') && this.props.moduleSetComplete) {
      this.props.updateAnswer(this.props.questionData.answer);
      this.props.done();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.questionData !== this.props.questionData) {
      // if answer checked; update state to indicate module is ready for completion.
      // else update state module not ready.
      const { moduleToBeCompleted, moduleUuid } = this.props;
      if (this.props.questionData.answer.length > 0) {
        moduleToBeCompleted({uuid: moduleUuid, set_complete: true});
      } else {
        moduleToBeCompleted({uuid: moduleUuid, set_complete: false});
      }
    }
  }

  close() {
    this.props.close();
  }

  render() {
    const { data, template, questionData } = this.props;
    const classes = ['s-module-section', `t-${template}`];

    return (
      <section className={classes.join(' ')}>
        <main className="container">
          <div className="inner-container">
            <method.LMSMultipleChoice
              {...this.props}
              userData={questionData}
              content={data.multiple_choice}
              className={'checkbox'}
              uuid={data.multiple_choice.uuid}
              options={data.multiple_choice.options}
              multiselect={true}
            />
            <LMSInteraction className="done" text={translate('MODULE_CLOSE')} onClick={this.close.bind(this)} visible />
          </div>
        </main>
      </section>
    );
  }
}

TemplateOutro.propTypes = {
  template: PropTypes.string,
  moduleUuid: PropTypes.string,
  moduleSetComplete: PropTypes.bool,
  data: PropTypes.object,
  questionData: PropTypes.object,
  done: PropTypes.func,
  close: PropTypes.func,
  updateAnswer: PropTypes.func,
  moduleToBeCompleted: PropTypes.func,
  moduleCompleted: PropTypes.bool,
};

export default TemplateOutro;

import template from './templates/';

const templates = {
  'intro': template.TemplateIntro,
  'content': template.TemplateContent,
  'checklist': template.TemplateChecklist,
  'background-article': template.TemplateBackgroundArticle,
  'quiz': template.TemplateQuiz,
  'mpc-text-validated': template.TemplateMPC,
  'mpc-text-generic': template.TemplateMPC,
  'outro': template.TemplateOutro,
};

function contentToTemplate(moduleData, data, index, router) {
  const props = { ...moduleData, index, router };
  return templates[data.template]({...props, uuid: data.uuid, template: data.template, data: data.content});
}

export { contentToTemplate };

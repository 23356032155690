import React from 'react';
import PropTypes from 'prop-types';

import LMSMultipleChoiceBase from 'qollap-lms/lib/methods/mpc_question/base';
import MPCOption from 'qollap-lms/lib/methods/mpc_question/option';
import MPCInput from 'qollap-lms/lib/methods/mpc_question/input';
import MPCIndicator from 'qollap-lms/lib/methods/mpc_question/indicator';
import './styles.css';

class LMSChecklist extends LMSMultipleChoiceBase {
  render() {
    const { answer, disabled } = this.getCurrentData();
    const { stateClass, options, uuid } = this.props;

    return (
      <article className={'lms-checklist' + (stateClass ? ` ${stateClass}` : '')}>
        {options.map((option, index) => {
          return (
            <MPCOption className="lms-checklist__option" multiselect={true} key={index}>
              <MPCInput
                uuid={uuid}
                index={index}
                disabled={disabled}
                multiselect={true}
                onChange={this.checkboxInputChange.bind(this, option.uuid)}
                checked={(answer || []).includes(option.uuid)}
              />
              <MPCIndicator className="lms-checklist__indicator" />
              <div className="lms-checklist__content">
                <p className="lms-checklist__title">{option.title}</p>
                <div className="lms-checklist__text" dangerouslySetInnerHTML={{__html: option.text }}></div>
              </div>
            </MPCOption>
          );
        })}
      </article>
    );
  }
}

LMSChecklist.propTypes = {
  stateClass: PropTypes.string,
  options: PropTypes.array,
};

export default LMSChecklist;

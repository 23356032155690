import { LocalApi, RemoteApi, ScormApi } from 'qollap-api';


function uuid() {
  let uuid = '';
  for (let i = 0; i < 32; i++) {
    let random = (Math.random() * 16) | 0;

    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    }
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
  }
  return uuid;
}

class CustomRemoteApi extends RemoteApi {
  async fetchInvite(payload) {
    const api = await this.apiPromise;
    const response = await api.action(['accounts', 'invites', 'read'],
      payload
    );
    return response;
  }

  async patchUserData(payload) {
    const api = await this.apiPromise;
    const response = await api.action(
      ['accounts', 'me', 'update_account'],
      payload
    );
    return response;
  }

  async fetchJourneys() {
    const api = await this.apiPromise;
    const response = await api.action(['lms', 'dashboard', 'list']);
    return response;
  }

  async setModuleActive(uuid) {
    const api = await this.apiPromise;
    const response = await api.action(['lms', 'modules', 'opened'], {
      uuid
    });
    return response;
  }

  async changePassword({ payload }) {
    const api = await this.apiPromise;
    const response = await api.action(['accounts', 'me', 'change_password'], {
      current_password: payload.currentPassword,
      new_password: payload.newPassword,
      new_password_repeat: payload.newPasswordRepeat
    });
    return response;
  }

  async sendFeedback(message) {
    const api = await this.apiPromise;
    const response = await api.action(['rating', 'platformrating', 'create'], {
      comment: message
    });
    return response;
  }

  async fetchPages() {
    const api = await this.apiPromise;
    const response = await api.action(['content', 'list'], {
      tags: ['pages']
    });
    return response;
  }

  async resetQuestion(uuid) {
    const api = await this.apiPromise;
    const response = await api.action(['lms', 'questions', 'delete_answer'], {
      uuid,
    });
    return response;
  }

  async fetchTodos() {
    const api = await this.apiPromise;
    return await api.action(['todos', 'list']);
  }

  async postTodo(data) {
    const api = await this.apiPromise;
    return await api.action(['todos', 'create'], {
      data
    });
  }

  async completeTodo(id) {
    const api = await this.apiPromise;
    return await api.action(['todos', 'complete'], {
      id
    });
  }

  async removeTodo(id) {
    const api = await this.apiPromise;
    return await api.action(['todos', 'delete'], {
      id
    });
  }

  async fetchModules() {
    const api = await this.apiPromise;
    const response = await api.action(['lms', 'dashboard', 'list']);
    return response.reduce((out, journey) =>
      [...out, ...journey.modules.map((m, index) => ({
        ...m,
        numb: index + 1,
        journey: journey.id,
      }))]
    , []);
  }

  async whatsupNotify() {
    const api = await this.apiPromise;
    return await api.action(['customer_specific', 'takeaway', 'whatsup_notify']);
  }

  async clearHelpNeeded(userId) {
    const api = await this.apiPromise;
    return await api.action(['customer_specific', 'takeaway', 'clear_help_needed'], {id: userId});
  }


  async fetchReport() {
    const api = await this.apiPromise;
    return await api.action(['customer_specific', 'takeaway', 'reporting']);
  }

  async fetchColleagues() {
    const api = await this.apiPromise;
    return await api.action(['groups', 'tree-group-member', 'list']);
  }

  async fetchNotifications(data) {
    const api = await this.apiPromise;
    return await api.action(['notifications', 'list'], data);
  }

  async seenAllNotifications() {
    const api = await this.apiPromise;
    return await api.action(['notifications', 'seen_all'], {
    });
  }

  async getNotificationSettings() {
    const api = await this.apiPromise;
    return await api.action(['notifications', 'settings', 'list']);
  }

  async updateNotificationSettings(settings) {
    const update = { id: settings.pk, send: settings.send };
    const api = await this.apiPromise;
    const response = await api.action(['notifications', 'settings', 'update'], update);
    return response;
  }
}

class CustomLocalApi extends LocalApi {
  async resetQuestion(uuid) {
    const questions = JSON.parse(localStorage.getItem('questions')) || {};
    delete questions[uuid];
    localStorage.setItem('questions', JSON.stringify(questions));
  }

  async setModuleActive(uuid) {
    // @TODO: write func for this local api request.
    return {};
  }

  async fetchTodos() {
    const todos = JSON.parse(localStorage.getItem('todos') || '[]');
    return todos;
  }

  async postTodo(data) {
    const todos = JSON.parse(localStorage.getItem('todos') || '[]');
    const updatedTodos = [...todos, { ...data }];
    localStorage.setItem('todos', JSON.stringify(updatedTodos));
    return { data };
  }

  async completeTodo(id) {
    const todos = JSON.parse(localStorage.getItem('todos') || '[]');
    const updatedTodos = todos.map(todo => todo.pk == id
      ? { ...todo, completed_at: new Date().valueOf() }
      : todo
    );
    localStorage.setItem('todos', JSON.stringify(updatedTodos));
  }

  async removeTodo(id) {
    const todos = JSON.parse(localStorage.getItem('todos') || '[]');
    const updatedTodos = todos.filter(todo => todo.pk == !id);
    localStorage.setItem('todos', JSON.stringify(updatedTodos));
  }
}

const options = {
  api: new CustomLocalApi()
};

function setApi(schemaUrl) {
  options.api = new CustomRemoteApi({ schemaUrl, auth: true });
}

function setScormApi(fetchContent = true) {
  options.api = new ScormApi(fetchContent);
}

function getApi() {
  return options.api;
}


export { setApi, setScormApi, getApi };

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LMSHeading, LMSText, LMSImage, LMSVideo, LMSOpen, LMSMultipleChoice, isMPCCorrect } from 'qollap-lms';

import { fetchAnswer, selectAnswer } from '../../store/module_player/actions';

import LMSSource from '../../components/lms_source';
import LMSTip from '../../components/lms_tip';
import LMSChecklist from '../../components/lms_checklist';


const questionState = (state, ownProps) => {
  const question = state.moduleUserData.questions[ownProps.uuid];
  const answered = (question && question.state === 'ANSWERED');
  const type = ownProps.content.object_type;
  // Add classes that represent the state of the question e.g. 'answered correct'
  const stateClasses = question ?
    ((answered && type !== 'question.open') ? (isMPCCorrect(ownProps.content, question.answer) ? ['state-correct'] : ['state-incorrect']) : []).concat(
      [`state-${question.state.toLowerCase()}`]
    ) : [];
  return {
    mediaUrl: state.mediaUrl,
    userData: question,
    editable: ownProps.content.editable || !answered,
    stateClass: stateClasses.join(' '),
  };
};

const questionActions = (dispatch, ownProps) => bindActionCreators({
  updateAnswer: (answer, submit) => {
    const type = ownProps.content.object_type;
    return selectAnswer({
      question: ownProps.uuid,
      answer: answer.map(answer => ({type: type === 'question.open' ? 'OPEN' : 'OPTION', value: answer})),
      submit: submit
    })
  },
  fetchAnswer: fetchAnswer.bind(null, ownProps.uuid)
}, dispatch);


export default {
  LMSMultipleChoice: connect(questionState, questionActions)(LMSMultipleChoice),
  LMSText,
  LMSOpen: connect(questionState, questionActions)(LMSOpen),
  LMSVideo: connect((state, ownProps) => ({
    src: state.mediaUrl + ownProps.src
  }))(LMSVideo),
  LMSImage: connect((state, ownProps) => ({
    src: state.mediaUrl + ownProps.src
  }))(LMSImage),
  LMSHeading,
  LMSSource,
  LMSTip,
  LMSChecklist: connect(questionState, questionActions)(LMSChecklist),
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { translate } from '../../utils';
import { IconPencil } from '../icons';
import './styles.css';


class FormField extends React.Component {
  render() {
    const { type, name, label, placeholder, colored, iconPencil, required, disabled, id, value, defaultValue, onChange, errors, className } = this.props;
    return (
      <div className={classNames({ 'c-form-field': true, 'c-form-field--colored': colored, [className]: className, 'errors': errors && errors.length > 0 })}>
        {label &&
          <div className="c-form-field__label-container">
            <label className="c-form-field__label" id={id} htmlFor={label}>{label}</label>
            {type === 'password' && iconPencil &&
              <Link to="/nav/settings/password">
                <IconPencil size="15" />
              </Link>
            }
          </div>
        }
        <input
          className="c-form-field__input"
          type={type}
          id={id}
          name={name}
          value={value}
          defaultValue={defaultValue}
          required={required}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
        />

        {errors && errors.map((error, index) =>
          <div key={index} className="c-form-field__error-container">
            <p>{translate(error)}</p>
          </div>
        )}
      </div>
    );
  }
}

FormField.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  colored: PropTypes.bool,
  iconPencil: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.array
};

export default FormField;

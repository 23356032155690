import { all, takeLatest, put } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';

import * as TYPES from './types';
import { getApi } from '../../api';
import { fetchUserData } from '../app/sagas';


function* changeUserPassword({ payload }) {
  const api = getApi();
  try {
    const response = yield api.changePassword({ payload });
    yield put({ type: TYPES.CHANGE_USER_PASSWORD_SUCCESS, response });
    yield put(push('/nav/settings'));
  } catch (error) {
    yield put({ type: TYPES.CHANGE_USER_PASSWORD_FAILED, error });
  }
}

function* changeUserSettings({ payload, intake }) {
  const api = getApi();
  try {
    const response = yield api.patchUserData(payload);
    yield put({ type: TYPES.CHANGE_USER_SETTINGS_SUCCESS, response });
    yield fetchUserData();
    if (intake) { yield put(replace('/')); }
  } catch (error) {
    yield put({ type: TYPES.CHANGE_USER_SETTINGS_FAILED, error });
  }
}

export function* getNotificationSettings() {
  const api = getApi();
  try {
    const response = yield api.getNotificationSettings();
    yield put({ type: TYPES.GET_NOTIFICATION_SETTINGS_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.GET_NOTIFICATION_SETTINGS_FAILED, message });
  }
}

function* updateNotificationSettings({type, payload}) {
  const api = getApi();
  try {
    yield api.updateNotificationSettings(payload);
    yield getNotificationSettings();
    yield put({ type: TYPES.UPDATE_NOTIFICATION_SETTINGS_SUCCESS });
  } catch ({ message }) {
    yield put({ type: TYPES.UPDATE_NOTIFICATION_SETTINGS_FAILED, message });
  }
}

function* changeUserPasswordSaga() {
  yield takeLatest(TYPES.CHANGE_USER_PASSWORD, changeUserPassword);
}

function* changeUserSettingsSaga() {
  yield takeLatest(TYPES.CHANGE_USER_SETTINGS, changeUserSettings);
}

function* getNotificationSettingsListener() {
  yield takeLatest(TYPES.GET_NOTIFICATION_SETTINGS, getNotificationSettings);
}

function* updateNotificationSettingsListener() {
  yield takeLatest(TYPES.UPDATE_NOTIFICATION_SETTINGS, updateNotificationSettings);
}

export default function* userSaga() {
  yield all([
    changeUserPasswordSaga(),
    changeUserSettingsSaga(),
    getNotificationSettingsListener(),
    updateNotificationSettingsListener(),
  ]);
}

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { translate } from '../../utils';
import Button from '../../components/button';
import Header from '../../components/header';
import Module from '../../components/module';
import './styles.css';


class Journey extends React.Component {
  state = {
    journey: {}
  }

  componentDidMount() {
    const journey = this.props.journeys.filter(journey => journey.id == this.props.match.params.journeyId)[0];
    this.setState({ journey });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.journeys !== this.props.journeys) {
      const journey = this.props.journeys.filter(journey => journey.id == this.props.match.params.journeyId)[0];
      this.setState({ journey });
    }
  }

  render() {
    const { journey } = this.state;
    const modules = (journey && journey.modules)
      ? journey.modules.map((module, i) => ({ ...module, index: i + 1 }))
      : undefined;

    return (
      <section className={classNames({ 's-journey': true, 'default-view-transition': true })}>
        <Header className="header" hamburger logo />

        <section className="container">
          <div className="info">
            <svg className="info__bg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
              <polygon fill="white" points="0,100 100,85 100,0 100,100"/>
            </svg>

            <div className="info__content">
              <h1 className="info__title">{journey.title}</h1>
              <p className="info__description">{journey.description}</p>
            </div>
          </div>

          {modules &&
            <div className="modules">
              {modules.map(module =>
                <Module
                  key={module.uuid}
                  uuid={module.uuid}
                  className={'module'}
                  title={module.meta.heading}
                  duration={module.meta.duration}
                  mandatory={module.meta.mandatory_module}
                  img={module.imageUrl}
                  completed={module.completed === 100}
                />
              )}
            </div>
          }

          <Button
            className="back-btn"
            text={translate('Back to dashboard')}
            onClick={() => this.props.history.push('/')}
          />
        </section>
      </section>
    );
  }
}

Journey.propTypes = {
  match: PropTypes.object,
  journeys: PropTypes.array,
  history: PropTypes.object
};

const mapStateToProps = state => {
  return {
    journeys: state.journeys,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Journey);

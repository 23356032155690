import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.css';

class Loader extends React.Component {
  render() {
    const { text, loading } = this.props;
    return (
      <section className={classNames({'c-loader': true, 'state-loading': loading})}>
        <p>{text}</p>
      </section>
    );
  }
}

Loader.propTypes = {
  text: PropTypes.string,
  loading: PropTypes.bool,
};

export default Loader;

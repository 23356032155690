import * as TYPES from './types';


const modules = (state = [], action) => {
  let payload;

  switch (action.type) {
    case TYPES.FETCH_MODULES_SUCCESS:
      payload = action.payload;
      payload = payload
        // return array of uuids.
        .map(module => module['uuid'])
        // store the keys of the unique objects.
        .map((uuid, i, final) => final.indexOf(uuid) === i && i)
        // eliminate the dead keys & store unique objects.
        .filter(e => payload[e]).map(e => payload[e]);

      payload.map((module, index) => { module.index = index + 1; });
      return payload;
  }

  return state;
};

const module = (state = {}, action) => {
  switch (action.type) {
    case TYPES.FETCH_MODULE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const moduleContent = (state = {}, action) => {
  let payload;

  switch (action.type) {
    case TYPES.FETCH_MODULES_SUCCESS:
      payload = action.payload;

      return payload.reduce((out, module) => ({
        ...out,
        [module.uuid]: {
          ...state[module.uuid],
          index: module.numb,
          journey: module.journey,
          title: module.title,
          duration: module.time,
          started: module.started_at,
          image: module.imageUrl,
          uuid: module.uuid,
          completed: module.completed,
        }
      }), state);
    case TYPES.FETCH_MODULE_CONTENT_SUCCESS:
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          ...{
            ...action.payload,
          }

        }
      };
    case TYPES.MODULE_TO_BE_COMPLETED:
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          set_complete: action.payload.set_complete
        }
      };
  }
  return state;
};

const moduleUserData = (state = {
  'answered': {},
  'questionResults': {},
  'questions': {},
  'questionsFetched': {},
  'questionsPosted': {}
}, action) => {
  switch (action.type) {
    case TYPES.POST_ANSWER_SUCCESS:
      return {
        ...state,
        questionsPosted: {
          ...state.questionsPosted,
          [action.questionUuid]: true
        },
      }
    case TYPES.RESET_QUESTION:
      delete state.questionsPosted[action.uuid];
      delete state.questions[action.uuid];
      delete state.answered[action.uuid];
      return state;
    case TYPES.POST_ANSWER:
      return {
        ...state,
        answered: {
          ...state.answered,
          [action.questionUuid]: true
        },
      }
    case TYPES.POST_ANSWER_FAILED:
      return {
        ...state,
        answered: {
          ...state.answered,
          [action.questionUuid]: false
        },
      }
    case TYPES.ANSWER_UPDATE:
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.question]: {
            answer: action.payload.answer,
            state: action.payload.state || ''
          }
        },
        questionsFetched: {
          ...state.questionsFetched,
          [action.payload.question]: true
        }
      }
    case TYPES.ANSWER_FETCHED:
      return {
        ...state,
        questionsFetched: {
          ...state.questionsFetched,
          [action.payload.question]: true
        }
      }
    case TYPES.FETCH_QUESTION_RESULTS_SUCCESS:
      return {
        ...state,
        questionResults: {
          ...state.questionResults,
          [action.payload.uuid]: action.payload.results
        }
      }
    case TYPES.CLEAR_ALL_ANSWERS_SUCCESS:
      return {
        ...state,
        'questionResults': {},
        'questions': {}
      }
    case TYPES.PREFILL_ALL_ANSWERS_SUCCESS:
      return {
        ...state,
        'questions': action.payload
      }
  }
  return state;
};

const mediaUrl = (state = '', action) => {
  switch (action.type) {
    case TYPES.FETCH_MODULE_CONTENT_SUCCESS:
      return action.payload['media_url'];
  }
  return state;
};


export default {
  modules,
  module,
  moduleContent,
  moduleUserData,
  mediaUrl
};

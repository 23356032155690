import React from 'react';
import { translate } from '../../utils';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { IconCheck, IconChevronDown } from '../../components/icons';
import './styles.css';

class ReportingGoal extends React.Component {
  state = {
    showModules: false
  }

  toggleShowModules = () => {
    this.setState((prevState) => ({
      showModules: !prevState.showModules
    }));
  };

  render() {
    const { showModules } = this.state;
    const { title, progress, modules } = this.props;

    return (
      <div className="c-reporting-goal" >
        <div className="c-rg__clickarea" onClick={this.toggleShowModules}>
          <div className="c-rg__container">
            <p className="c-rg__title">{title}</p>
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: `${progress}%` }} />
            </div>
          </div>
          <IconChevronDown className={classnames({'c-rg__icon': true, 'state-up': showModules })} />
        </div>


        <div
          className={classnames({'c-rg__modules': true, 'state-hidden': !showModules })}
        >
          {modules && modules.map((module, index) =>
            <div className="c-rg__module" key={index}>
              <div className="c-rg__module-text">
                <p className="c-rg__module-title">{module.meta.heading}</p>
                {module.meta.mandatory_module && <p className="c-rg__mandatory">{translate('MODULE_MANDATORY_TEXT')}</p>}
              </div>
              {module.completed &&
                <div className="c-rg__completed">
                  <IconCheck />
                </div>
              }
            </div>
          )}
        </div>
      </div>
    )
  }
}

ReportingGoal.propTypes = {
  title: PropTypes.string,
  progress: PropTypes.number,
  modules: PropTypes.array,
};

export default(ReportingGoal);


import * as TYPES from './types';


export const addTodo = (data) => ({
  type: TYPES.POST_TODO,
  payload: data
});

export const removeTodo = (data) => ({
  type: TYPES.REMOVE_TODO,
  payload: data
});

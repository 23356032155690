import { all, put, takeEvery, select } from 'redux-saga/effects';

import { getApi } from '../../api';
import * as TYPES from './types';
import { updateAnswer } from './actions';
import { fetchJourneys } from '../dashboard/sagas';
import { fetchFlexibleFields } from '../app/sagas';
import { completeTodo } from '../progress/sagas';
import trackingActions from '../tracking/actions';


export function* postAnswer(data) {
  const api = getApi();
  yield put({ type: TYPES.POST_ANSWER, questionUuid: data.uuid });
  try {
    const response = yield api.postAnswer(data);
    yield put({ type: TYPES.POST_ANSWER_SUCCESS, payload: response, questionUuid: data.uuid });
  } catch ({ message }) {
    yield put({ type: TYPES.POST_ANSWER_FAILED, message });
  }
}

export function* postAnswerData(data) {
  const api = getApi();
  try {
    const response = yield api.postAnswerData(data);
    yield put({ type: TYPES.POST_ANSWER_DATA_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.POST_ANSWER_DATA_FAILED, message });
  }
}

export function* fetchModuleExcerpts(uuids) {
  const api = getApi();
  for (let i = 0; i < uuids.length; i++) {
    const uuid = uuids[i];
    try {
      const response = yield api.fetchModuleExcerpt(uuid);
      yield put({
        type: TYPES.FETCH_MODULE_EXCERPT_SUCCESS,
        payload: response
      });
    } catch ({ message }) {
      yield put({
        type: TYPES.FETCH_MODULE_EXCERPT_FAILED,
        payload: { uuid, message }
      });
    }
  }
}

export function* prefillAllAnswersSaga(data) {
  try {
    localStorage.setItem('questions', data.payload.local);
    yield put({
      type: TYPES.PREFILL_ALL_ANSWERS_SUCCESS,
      payload: data.payload.state
    });
  } catch ({ message }) {
    yield put({
      type: TYPES.PREFILL_ALL_ANSWERS_FAILED,
      payload: data.payload
    });
  }
}

export function* clearAllAnswersSaga() {
  try {
    localStorage.clear();
    yield put({ type: TYPES.CLEAR_ALL_ANSWERS_SUCCESS });
  } catch ({ message }) {
    yield put({ type: TYPES.CLEAR_ALL_ANSWERS_FAILED });
  }
}

export function* fetchModuleContentSaga({ uuid }) {
  const api = getApi();
  try {
    const response = yield api.fetchModuleContent(uuid);
    yield put({ type: TYPES.FETCH_MODULE_CONTENT_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({
      type: TYPES.FETCH_MODULE_CONTENT_FAILED,
      payload: { uuid, message }
    });
  }
}

export function* fetchModulesSaga() {
  const api = getApi();
  try {
    const response = yield api.fetchModules();
    yield put({ type: TYPES.FETCH_MODULES_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_MODULES_FAILED, payload: { message } });
  }
}

export function* fetchModuleSaga({ uuid }) {
  const api = getApi();
  try {
    const response = yield api.fetchModules();
    const payload = yield response.filter((item) => {
      return item.uuid === uuid
    });
    yield put({ type: TYPES.FETCH_MODULE_SUCCESS, payload: payload[0] });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_MODULE_FAILED, payload: { message } });
  }
}

export function* fetchQuestionsResults(uuids) {
  const api = getApi();
  for (let i = 0; i < uuids.length; i++) {
    const uuid = uuids[i];
    try {
      const response = yield api.fetchQuestionResults(uuid);
      yield put({
        type: TYPES.FETCH_QUESTION_RESULTS_SUCCESS,
        payload: response
      });
    } catch ({ message }) {
      yield put({
        type: TYPES.FETCH_QUESTION_RESULTS_FAILED,
        payload: { uuid, message }
      });
    }
  }
}

export function* selectAnswerSaga(data) {
  if (data.payload.submit) {
    if (data.payload.reset) {
      yield resetQuestion({uuid: data.payload.question});
    }

    let complex = false;
    for (let i = 0; i < data.payload.answer.length; i++) {
      const answer = data.payload.answer[i];
      switch (answer.type) {
        case 'OPTION':
          yield postAnswer({
            uuid: data.payload.question,
            option_fk: answer.value
          });
          break;
        case 'OPEN':
          yield postAnswer({
            uuid: data.payload.question,
            answer: answer.value
          });
          break;
        case 'UPLOAD_WITH_TEXT':
          yield postAnswer({
            uuid: data.payload.question,
            upload: answer.value.upload,
            answer: answer.value.answer
          });
          break;
        case 'UPLOAD':
          yield postAnswer({
            uuid: data.payload.question,
            upload: answer.value
          });
          break;
        case 'DRAG_AND_DROP':
          yield postAnswerData({
            uuid: data.payload.question,
            answer_data: Object.keys(answer.value).map(dock => ({
              uuid: dock,
              items: answer.value[dock]
            }))
          });
          complex = true;
      }
    }
    yield fetchAnswerSaga({ uuid: data.payload.question, complex });
  } else {
    yield put(updateAnswer(data.payload));
  }
}

export function* resetQuestion({uuid}) {
  const api = getApi();
  try {
    yield api.resetQuestion(uuid);
  } catch (e) { }
}

export function* completeModuleSaga({ uuid }) {
  const api = getApi();
  try {
    // complete module and update the modules state.
    yield api.completeModule(uuid);
    yield fetchModulesSaga();

    // track finishing the module
    yield put(trackingActions.trackModuleFinished(uuid));

    // check if module is in todo list, then complete it.
    const todos = yield select(state => state.todos);
    const todo = todos.filter(todo => todo.data.uuid === uuid)[0];
    if (todo) { yield completeTodo({payload: todo}); }

    // fetch journeys and flexible fields so you can grab the newest state
    // and push the payload to a reducer to check for celebration pop-up.
    yield fetchJourneys();
    yield fetchFlexibleFields();
    const journeys = yield select(state => state.journeys);
    const flexibleFields = yield select(state => state.flexibleFields);
    const response = { journeys, flexibleFields };
    yield put({ type: TYPES.COMPLETE_MODULE_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.COMPLETE_MODULE_FAILED, payload: { message } });
  }
}

export function* activateModule({ uuid }) {
  const api = getApi();
  try {
    yield api.setModuleActive(uuid);
    yield put({ type: TYPES.MODULE_ACTIVE_SUCCESS });
    yield fetchModulesSaga();
  } catch ({ message }) {
    yield put({ type: TYPES.MODULE_ACTIVE_FAILED, message });
  }
}

export function* fetchAnswerSaga({ uuid, complex }) {
  const api = getApi();
  try {
    const response = complex
      ? yield api.fetchAnswerData(uuid)
      : yield api.fetchAnswer(uuid);
    if (complex && response.answer_data.length > 0) {
      yield put({
        type: TYPES.ANSWER_UPDATE,
        payload: {
          question: uuid,
          answer: response.answer_data,
          state: 'ANSWERED'
        }
      });
    } else if (response.length > 0) {
      const answer = response[0].upload
        ? response
        : response[0].drag_and_drop
          ? response.map(answer => answer.drag_and_drop)
          : response[0].option_fk
            ? response.map(answer => answer.option_fk)
            : response[0].answer;

      yield put({
        type: TYPES.ANSWER_UPDATE,
        payload: {
          question: uuid,
          answer: answer,
          state: 'ANSWERED'
        }
      });
    } else {
      yield put({
        type: TYPES.ANSWER_FETCHED,
        payload: {
          question: uuid
        }
      });
    }
  } catch (e) {
    yield put({
      type: TYPES.ANSWER_FETCHED,
      payload: {
        question: uuid
      }
    });
  }
}

function* resetQuestionListener() {
  yield takeEvery(TYPES.RESET_QUESTION, resetQuestion);
}

function* selectEveryAnswerSaga() {
  yield takeEvery(TYPES.ANSWER_SELECT, selectAnswerSaga);
}

function* fetchEveryAnswerSaga() {
  yield takeEvery(TYPES.FETCH_ANSWER, fetchAnswerSaga);
}

function* prefillEveryAnswerSaga() {
  yield takeEvery(TYPES.PREFILL_ALL_ANSWERS, prefillAllAnswersSaga);
}

function* clearEveryAnswerSaga() {
  yield takeEvery(TYPES.CLEAR_ALL_ANSWERS, clearAllAnswersSaga);
}

function* fetchEveryModuleContentSaga() {
  yield takeEvery(TYPES.FETCH_MODULE_CONTENT, fetchModuleContentSaga);
}

function* fetchEveryCompleteModuleSaga() {
  yield takeEvery(TYPES.COMPLETE_MODULE, completeModuleSaga);
}

function* setModuleActiveListener() {
  yield takeEvery(TYPES.MODULE_ACTIVE, activateModule);
}

function* fetchEveryfetchModuleSaga() {
  yield takeEvery(TYPES.FETCH_MODULE, fetchModuleSaga);
}


export default function* lmsSaga() {
  yield all([
    resetQuestionListener(),
    selectEveryAnswerSaga(),
    fetchEveryAnswerSaga(),
    prefillEveryAnswerSaga(),
    clearEveryAnswerSaga(),
    fetchEveryModuleContentSaga(),
    fetchEveryCompleteModuleSaga(),
    setModuleActiveListener(),
    fetchEveryfetchModuleSaga()
  ]);
}

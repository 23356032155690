import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.css';


class LMSSource extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { className, text } = this.props;

    return (
      <p className={classNames({'lms-source': true, [className]: className })}>
        {text}
      </p>
    );
  }
}

LMSSource.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default LMSSource;

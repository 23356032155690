import * as TYPES from './types';
import { COMPLETE_MODULE, COMPLETE_MODULE_SUCCESS } from '../module_player/types';
import { CHANGE_USER_PASSWORD_FAILED, CHANGE_USER_SETTINGS_FAILED } from '../user/types';

const invite = (state = {}, action) => {
  let payload;

  switch (action.type) {
    case TYPES.FETCH_INVITE_SUCCESS:
      payload = action.payload;
      return payload;
  }

  return state;
};

const loader = (state = {}, action) => {
  switch (action.type) {
    case COMPLETE_MODULE:
      return {
        ...state,
        completeModule: true
      };
    case COMPLETE_MODULE_SUCCESS:
      delete state.completeModule;
      return state;
  }

  return state;
};

const firstVisit = (state = null, action) => {
  switch (action.type) {
    case TYPES.FETCH_FLEXIBLE_FIELDS_SUCCESS:
      return action.payload.first_visit === undefined
        ? true
        : action.payload.first_visit;
  }
  return state;
};

const flexibleFields = (state = {}, action) => {
  switch (action.type) {
    case TYPES.FETCH_FLEXIBLE_FIELDS_SUCCESS:
      return action.payload;
  }
  return state;
};

const pages = (state = [], action) => {
  let payload;

  switch (action.type) {
    case TYPES.FETCH_PAGES_SUCCESS:
      payload = action.payload;
      return payload;
  }

  return state;
};

const translations = (state = {}, action) => {
  let payload;

  switch (action.type) {
    case TYPES.FETCH_TRANSLATIONS_SUCCESS:
      payload = action.payload;
      return {
        loaded: true,
        data: payload
      };
  }

  return state;
};

const userData = (state = {}, action) => {
  let payload;

  switch (action.type) {
    case TYPES.FETCH_USER_DATA_SUCCESS:
      payload = action.payload;
      return payload;
  }

  return state;
};

const feedback = (state = {}, action) => {
  switch(action.type) {
    case TYPES.SEND_USER_FEEDBACK_SUCCESS:
      return {
        ...state,
        success: true
      };
    case TYPES.CLEAR_UI_FEEDBACK:
      return {};
    default:
      return state;
  }
};

const celebration = (state = {}, action) => {
  let payload;
  let milestones = [];
  let newMilestones;

  switch (action.type) {
    case COMPLETE_MODULE_SUCCESS: {
      payload = action.payload;
      const seen = payload.flexibleFields.celebrations_seen ? JSON.parse(payload.flexibleFields.celebrations_seen) : [];

      // create array of all modules to calculate overall progress
      const modulesArray = payload.journeys.reduce((out, journey) =>
        [...out, ...journey.modules.map((m, index) => ({
          ...m,
          numb: index + 1,
          journey: journey.id,
        }))]
      , []);

      const allModules = modulesArray.length;
      const completedModules = modulesArray.filter(module => module.completed === 100).length;

      // map module completion per journey
      payload.journeys = payload.journeys.map(journey => {
        return {
          ...journey,
          modules_total: journey.modules.length,
          modules_completed: journey.modules.filter(module => module.completed === 100).length
        };
      });

      // check if any of the below conditions evaluate to true.
      // fill 'milestones' array with all milestones that have already been reached.

      // 100% completion --> 100
      completedModules === allModules && milestones.push(100);
      // 50% completion --> 50
      completedModules / allModules >= 0.5 && milestones.push(50);
      // For each completed journey fill 'milestones' array with journey id
      const completedJourneys = payload.journeys.filter(journey => journey.progress === 100);
      const completedJourneysIds = completedJourneys.map(journey => journey.id);
      milestones = [...milestones, ...completedJourneysIds];

      // create a copy of 'milestones' array that can be manipulated
      // compare newMilestones with flexfield 'seen' and filter out the value that has not been celebrated yet
      newMilestones = milestones.filter(milestone => !seen.includes(milestone));

      return {
        ...state,
        // the current milestone(s) that haven't been celebrated yet
        current: newMilestones.length > 0 ? newMilestones : false,
        // the milestones that have been already reached by the user, because of previous actions.
        seen: seen,
      };
    }
    default:
      return state;
  }
};

const report = (state = {}, action) => {
  switch(action.type) {
    case TYPES.FETCH_REPORT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const colleagues = (state = [], action) => {
  switch(action.type) {
    case TYPES.FETCH_COLLEAGUES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const success = (state = {}, action) => {
  let payload;

  switch (action.type) {
    case TYPES.RESET_PASSWORD:
      delete state.forgotPass;
      return state;
    case TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPass: true
      };
    case TYPES.CONFIRM_PASSWORD:
      payload = action.payload;
      payload.register
        ? delete state.register
        : delete state.confirmPass;
      return state;
    case TYPES.CONFIRM_PASSWORD_SUCCESS:
      payload = action.payload;
      payload = payload.register ? { register: true } : { confirmPass: true };
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const errors = (state = {}, action) => {
  switch (action.type) {
    case TYPES.RESET_PASSWORD_FAILED:
      return {
        ...state,
        forgotPass: action.error && action.error.content
      };
    case TYPES.CONFIRM_PASSWORD_FAILED:
      return {
        ...state,
        confirmPass: action.error && action.error.content
      };
    case TYPES.USER_AUTHENTICATED_FAILED:
      return {
        ...state,
        login: action.error && action.error.content
      };
    case TYPES.POST_REGISTER_FAILED:
      return {
        ...state,
        register: action.error && action.error.content
      };
    case CHANGE_USER_PASSWORD_FAILED:
      return {
        ...state,
        changePassword: action.error.content
      };
    case CHANGE_USER_SETTINGS_FAILED:
      return {
        ...state,
        userSettings: action.error.content
      };
    default:
      return state;
  }
};

const notifications = (state = {}, action) => {
  switch (action.type) {
    case TYPES.FETCH_NOTIFICATIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default {
  invite,
  loader,
  firstVisit,
  flexibleFields,
  pages,
  userData,
  translations,
  feedback,
  celebration,
  success,
  errors,
  report,
  notifications,
  colleagues,
};

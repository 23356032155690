import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './styles.css';

class SectionTitle extends React.Component {
  render() {
    const { className, text } = this.props;
    return (
      <h2 className={classnames({'c-section-title': true, [className]: className})}>{text}</h2>
    );
  }
}

SectionTitle.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
}

export default SectionTitle;

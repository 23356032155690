import React from 'react';

import method from './work-methods';

function contentToComponent(content, key) {
  const props = {key, content}

  switch (content.type) {
    case 'heading':
      return (
        <method.LMSHeading
          {...props}
          title={content.title}
        />
      )

    case 'text':
      return (
        <method.LMSText
          {...props}
          text={content.value}
        />
      )

    case 'image':
      return (
        <method.LMSImage
          {...props}
          src={content.image}
          alt={content.alt}
        />
      )

    case 'video':
      return (
        <method.LMSVideo
          {...props}
          src={content.video || content.url}
          poster={content.poster}
        />
      )

    case 'multiple_choice':
      return (
        <method.LMSMultipleChoice
          {...props}
          uuid={content.uuid}
          options={content.options}
          multiselect={content.multi_select}
          instant
        />
      )

    // custom work-method.
    case 'source':
      return (
        <method.LMSSource
          {...props}
          text={content.text}
        />
      )

    // custom work-method.
    case 'tip':
      return (
        <method.LMSTip
          {...props}
          text={content.heading}
        />
      )

    default:
      console.warn('UNKNOWN METHOD', content)
      return <div {...props}/>
  }
}

export { contentToComponent }

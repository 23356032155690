import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../utils';

import './styles.css';
import { LogoTakeaway, LogoTakeOff } from '../icons';

class Splashscreen extends React.Component {
  render() {
    const { active } = this.props;

    return active && (
      <>
        <div className={'c-splashscreen'}>
          <p className="splash-txt">{translate('Get ready for')}</p>
          <LogoTakeOff size="250" />
        </div>
        <div className="splash-footer">
          <LogoTakeaway />
          <p className="splash-footer-txt">{translate('An onboarding experience by Takeaway.com')}</p>
        </div>
      </>
    );
  }
}

Splashscreen.propTypes = {
  active: PropTypes.bool,
};

export default Splashscreen;

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LMSConfigurator } from 'qollap-lms';

import { prefillAllAnswers, clearAllAnswers } from '../../store/module_player/actions';


const mapStateToProps = (state, ownProps) => {
  return ({});
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  clearAllAnswers,
  prefillAllAnswers: (data) => {
    let state = {};

    try {
      Object.keys(data).map(key => {
        const answer = data[key].answer_data ? data[key].answer_data
          : data[key].answer[0].upload ? [data[key].answer[answer.length - 1]]
          : data[key].answer[0].drag_and_drop ? [data[key].answer[0].drag_and_drop]
          : data[key].answer[0].option_fk ? data[key].answer.map(answer => answer.option_fk)
          : [data[key].answer[0].answer];

        state = {
          ...state,
          [key]: {
            answer: answer,
            state: 'ANSWERED'
          }
        }
      });
    } catch(error) {
      console.warn(error)
    }

    return prefillAllAnswers({
      local: JSON.stringify(data),
      state: state,
    })
  }
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LMSConfigurator);

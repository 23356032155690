import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IconChevronDown } from '../icons';

import './styles.css';

class Accordion extends React.Component {
  render() {
    const { title, content, isOpen, toggleOpen, toggleClose } = this.props;
    return (
      <div className={classnames({'c-accordion': true, 'active': isOpen })} onClick={isOpen ? toggleClose : toggleOpen}>
        <IconChevronDown className="c-accordion__icon" />
        <div className="c-accordion__content">
          <p className="c-accordion__title">{title}</p>
          {isOpen && <div className="c-accordion__text" dangerouslySetInnerHTML={{__html: content }}></div>}
        </div>
      </div>
    )
  }
}

Accordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func,
  toggleClose: PropTypes.func
};

export default Accordion;

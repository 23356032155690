import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { translate } from '../../utils';
import { Link } from 'react-router-dom';

import { IconCheck, IconClock } from '../icons';
import './styles.css';

class Todo extends React.Component {
  render() {
    const { title , duration, completed, mandatory, uuid } = this.props;
    return (
      <Link className={classnames({ 'c-todo': true, 'state-completed': completed })} to={`/todo/${uuid}`}>
        <label className="c-todo__checkbox">
          <input type="checkbox" />
          {/* Keeping old checkbox code just in case is needeed on the future */}
          {/* <span className="c-todo__indicator"><IconCheck /></span> */}
          <div className="c-todo__label">
            <span className="c-todo__text">{title}</span>
            {mandatory && <span className="c-todo__mandatory">{translate('TODO_MANDATORY_TEXT')}</span>}
          </div>
        </label>

        {duration &&
          <div className="c-todo__duration">
            <IconClock color="#C0CFD4"/>
            {duration} {translate('TODO_DURATION_TEXT')}
          </div>
        }
      </Link>
    );
  }
}

Todo.propTypes = {
  title: PropTypes.string,
  uuid: PropTypes.string,
  duration: PropTypes.number,
  completed: PropTypes.bool,
  mandatory: PropTypes.bool,
};

export default Todo;

import * as TYPES from './types';


const trackModuleOpened = (uuid) => ({
  type: TYPES.MODULE_OPENED,
  uuid
});

const trackModuleFinished = (uuid) => ({
  type: TYPES.MODULE_FINISHED,
  uuid
});

const storeModuleAttempt = (uuid) => ({
  type: TYPES.MODULE_ATTEMPT,
  uuid
});

const clearModuleAttempt = () => ({
  type: TYPES.CLEAR_MODULE_ATTEMPT
});

const trackingActions =  {
  trackModuleOpened,
  trackModuleFinished,
  storeModuleAttempt,
  clearModuleAttempt
}

export default trackingActions;
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from '../../utils';
import { changeUserSettings } from '../../store/user/actions';
import { updateFirstVisit } from '../../store/app/actions';

import Header from '../../components/header';
import Button from '../../components/button';
import { IconArrowUpload } from '../../components/icons';
import './styles.css';

class Intake extends React.Component {
  state = {};

  changePhoto(event) {
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ image: reader.result });
    };
    event.target.files[0] && reader.readAsDataURL(event.target.files[0]);
  }

  save(e) {
    e.preventDefault();
    let data= {};

    data.profile_flexible_field = { first_visit: false };

    if (this.state.image) {
      data.image = this.state.image;
    }

    if (this.state.dish) {
      data.profile_flexible_field = { ...data.profile_flexible_field, favourite_dish: this.state.dish };
    }

    this.props.changeUserSettings(data, true);
  }

  render() {
    const { image } = this.state;

    return (
      <section className="s-intake">
        <Header logo />
        <section className="container">
          <h1 className="s-intake__title">{translate('INTAKE_TITLE')}</h1>
          <p className="s-intake__body">{translate('INTAKE_DESCRIPTION')}</p>

          <form className="s-intake__form" onSubmit={this.save.bind(this)}>
            <h3 className="s-intake__step">{translate('INTAKE_STEP1')}</h3>
            <p className="s-intake__body">{translate('INTAKE_STEP1_TEXT')}</p>

            <div className="img-container">
              {image &&
                <img src={image} />
              }
              <input
                required
                type="file"
                name="image"
                onChange={this.changePhoto.bind(this)}
              />
              <IconArrowUpload />
              <p>{translate('INTAKE_UPLOAD')}</p>
            </div>

            <h3 className="s-intake__step">{translate('INTAKE_STEP2')}</h3>
            <p className="s-intake__body">{translate('INTAKE_STEP2_TEXT')}</p>
            <input
              required
              type="text"
              name="dish"
              className="s-intake__input"
              onChange={(e) => { this.setState({ dish: e.target.value }); }}
              placeholder={translate('YOUR_FAVOURITE_DISH')}
            />

            <p className="s-intake__body">{translate('INTAKE_FOOTER')}</p>

            <Button elem="button" className="c-button" text={translate('INTAKE_BTN_DONE')} />
          </form>
        </section>
      </section>
    )
  }
}

Intake.propTypes = {
  changeUserSettings: PropTypes.func,
  updateFirstVisit: PropTypes.func,
  history: PropTypes.object
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => bindActionCreators({
  changeUserSettings,
  updateFirstVisit
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Intake);

import React from 'react';
import Button from '../../components/button';
import Header from '../../components/header';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendUserFeedback, clearUIFeedback } from '../../store/app/actions';
import PropTypes from 'prop-types';

import { IconThumbsUp } from '../../components/icons';
import { translate } from '../../utils';
import './styles.css';

class Feedback extends React.Component {

  state = {
    message: '',
  }

  submit(e) {
    e.preventDefault();
    this.props.sendUserFeedback(this.state.message);
    this.setState({message: ''});
  }

  close() {
    this.props.clearUIFeedback();
  }

  render() {
    return (
      <div className="s-feedback">
        <Header
          hamburger
          hamburgerColor="#0A3847"
          logo
          logoColor="#FF8000"
        />

        <div className="s-feedback__container">
          <h1 className="s-feedback__title">{translate('FEEDBACK_TITLE')}</h1>
          <p className="s-feedback__text">{translate('FEEDBACK_DESCRIPTION')}</p>
          <h3 className="s-feedback__sharetxt">{translate('FEEDBACK_SHARE_TEXT')}</h3>

          {this.props.feedback.success &&
            <div className="success-container">
              <div className="success-frame">
                <IconThumbsUp size="48" />
                <p>{translate('FEEDBACK_SENT')}</p>
                <Button className="c-button" text={translate('FEEDBACK_DONE')} onClick={this.close.bind(this)} />
              </div>
            </div>
          }

          <form onSubmit={this.submit.bind(this)}>
            <textarea
              className="s-feedback__input"
              placeholder={translate('FEEDBACK_PLACEHOLDER')}
              required
              value={this.state.message}
              onChange={(e) => { this.setState({ message: e.target.value }); }}
            />
            <Button elem="button" text={translate('FEEDBACK_SUBMIT')} />
          </form>
        </div>
      </div>
    );
  }
}

Feedback.propTypes = {
  history: PropTypes.object,
  sendUserFeedback: PropTypes.func,
  clearUIFeedback: PropTypes.func,
  feedback: PropTypes.object,
  close: PropTypes.func,
};

const mapStateToProps = state => {
  const feedback = state.feedback;

  return {
    feedback
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  sendUserFeedback,
  clearUIFeedback
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Feedback);

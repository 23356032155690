import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from '../../utils';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Accordion from '../../components/accordion';
import Header from '../../components/header';
import { IconPlay } from '../../components/icons'
import './styles.css';

class Faq extends React.Component {
  state = {};

  toggleOpen = (i) => {
    this.setState({ openItemIndex: i });
  }

  toggleClose = () => {
    this.setState({ openItemIndex: undefined });
  }

  render() {
    const { questions } = this.props;
    const { openItemIndex } = this.state;

    return (
      <div className={classNames({ 's-faq': true, 'default-view-transition': true })}>
        <Header
          hamburger
          hamburgerColor="#0A3847"
          logo
          logoColor="#FF8000"
        />

        <div className="s-faq__container">
          <h1 className="s-faq__title">{translate('FAQ_TITLE')}</h1>
          <p className="s-faq__text">{translate('FAQ_TEXT')}</p>

          <Link className="s-faq__intro" to="/intro">
            <IconPlay />
            <p className="s-faq__playtext">{translate('FAQ_PLAY_INTRO')}</p>
          </Link>
        </div>

        <div className="s-faq__questions">
          {questions.map((question, i) =>
            <Accordion
              key={question.uuid}
              title={question.heading}
              content={question.text}
              isOpen={openItemIndex === i}
              toggleOpen={this.toggleOpen.bind(this, i)}
              toggleClose={this.toggleClose.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }
}

Faq.propTypes = {
  questions: PropTypes.array,
};

const mapStateToProps = state => {
  const faq = state.pages.filter(page => page.title == 'FAQ')[0];
  const questions = faq && faq.content.questions;

  return {
    questions
  };
};

export default connect(mapStateToProps)(Faq);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {sendUserFeedback, clearUIFeedback, postWhatsup, postFlexibleFields} from '../../store/app/actions';
import { translate } from '../../utils';
import Button from '../../components/button';
import WhatsupAsset from '../../assets/images/asset_whatsup.svg';
import { IconSmileSad, IconSmileNeutral, IconSmileHappy, IconThumbsUp } from '../../components/icons';
import './styles.css';

class Whatsup extends React.Component {

  state = {
    active: false,
    page: 1,
    currentQuestionnaire: {},
    currentWhatsup: {},
    whatsupToAdd: {},
    message: '',
    timeFrame: undefined
  }

  getTimeFrame = () => {
    // get dates.
    const startDate = this.props.startDate;
    const currentDate = this.props.currentDate;

    // calculate difference in weeks between startdate and current date.
    let day = (currentDate.getTime() - startDate.getTime()) / 1000;
    day /= (60 * 60 * 24);

    // get current timeframe.
    const timeFrame =
      day >= 89  ? 4
        : day >= 59  ? 3
        : day >= 29  ? 2
          : day >= 13 ? 1
            : day >= 6 ? 0
              : null;

    if(timeFrame !== null) {
      this.setState({ timeFrame: timeFrame }, () => {this.showCurrentQuestionnaire();});
    }
  }

  showCurrentQuestionnaire = () => {
    // grab questionnaire with current timeframe as an index
    this.setState(() => {
      return {
        currentQuestionnaire: this.props.questionnairesArray && this.props.questionnairesArray[this.state.timeFrame]
      };
    });

    // Show whatsupModal only if the current timeFrame has reached or passed the whatsup_level
    if(this.state.timeFrame >= 0 && this.state.timeFrame >= this.props.whatsupLevel) {
      this.setState({ active: true });
    } else {
      return null;
    }
  }

  storeWhatsupOverallMood = (e) => {
    this.setState({
      currentWhatsup: {
        overall: e.target.value
      }
    });
  }

  storeWhatsupMood(uuid, header, text, e) {
    // Save answers in a state object with each uuid as key
    const questionNumber = uuid;
    const questionAnswer = e.target.value;
    const addedAnswer = { [questionNumber]:
      { goal_title: header,
        question_txt: text,
        answer: questionAnswer
      }
    };

    this.setState((prevState) => ({
      currentWhatsup: Object.assign(prevState.currentWhatsup, addedAnswer)
    }));
  }

  storeWhatsupNeeds = (e) => {
    // Save current need as object
    const currentDate = this.props.currentDate;
    const currentNeed = {
      needs: {
        [currentDate]: this.state.whatsupNeeds
      }
    };

    // Add needs to whatsup state object
    this.setState((prevState) => ({
      currentWhatsup: Object.assign(prevState.currentWhatsup, currentNeed)
    }), () => {this.storeToTimeFrame(e);});
  }

  storeToTimeFrame(e) {
    // Store answers into a timeframe object for reporting
    const timeFrame = this.state.timeFrame;
    const whatsupToAdd = { [timeFrame]: this.state.currentWhatsup };

    this.setState((prevState) => ({
      whatsupToAdd: Object.assign(prevState.whatsupToAdd, whatsupToAdd)
    }), () => {
      this.next(e);
      this.saveWhatsupAnswers();
    });
  }

  saveWhatsupAnswers() {
    // Grab previous whatsup answers, if any, and append new answers
    const previousWhatsupString = this.props.whatsupAnswers;
    const previousWhatsupObject = previousWhatsupString ? JSON.parse(previousWhatsupString) : {};
    const newWhatsupObject = Object.assign(previousWhatsupObject, this.state.whatsupToAdd);

    // Stringify new whatsup object and save to flexible field
    const newWhatsupString = JSON.stringify(newWhatsupObject);
    this.props.postFlexibleFields({ whatsup: newWhatsupString });
  }

  submitFeedback = (e) => {
    if(this.state.message) {
      this.props.sendUserFeedback(this.state.message);
      this.setState({message: ''});
      this.complete(e);
    } else {
      this.complete(e);
      this.close();
    }
  }

  next(e) {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        page: prevState.page + 1
      };
    });
  }

  complete(e) {
    e.preventDefault();
    this.props.postWhatsup({ whatsup_level: this.state.timeFrame + 1 });
  }

  close() {
    this.props.clearUIFeedback();
    this.setState({ active: false });
  }

  componentDidMount() {
    (this.props.currentDate && this.props.startDate) && this.getTimeFrame();
    isNaN(this.props.whatsupLevel) && this.props.postFlexibleFields({ whatsup_level: 0 });
  }

  render() {
    const { active, page, currentQuestionnaire } = this.state;
    const { feedback } = this.props;

    return active && (

      <div className="s-whatsup-container">
        <div className="whatsup">
          <div className="whatsup__content">
            <p className="whatsup__counter">{page} / 4</p>
            <h1 className="whatsup__title">{translate('WHATSUP_TITLE')}</h1>

            {page === 1 &&
              <>
                <img src={WhatsupAsset} className="whatsup__img" />
                <p className="whatsup__text-bold">{translate('WHATSUP_INTRO_QUESTION')}</p>
                <p className="whatsup__text">{translate('WHATSUP_INTRO_TEXT')}</p>
                <form className="whatsup__question whatsup__question--overall" onSubmit={this.next.bind(this)}>
                  <h4>{translate('WHATSUP_OVERALL_QUESTION_TITLE')}</h4>
                  <p>{currentQuestionnaire && currentQuestionnaire.overall_mood}</p>
                  <div className="whatsup__answers">
                    <div className="whatsup__answer">
                      <input required type="radio" id="happy" name="overall" value="happy" onClick={this.storeWhatsupOverallMood} />
                      <label className="whatsup__btn" htmlFor="happy"><IconSmileHappy /></label>
                    </div>
                    <div className="whatsup__answer">
                      <input required type="radio" id="neutral" name="overall" value="neutral" onClick={this.storeWhatsupOverallMood} />
                      <label className="whatsup__btn" htmlFor="neutral"><IconSmileNeutral /></label>
                    </div>
                    <div className="whatsup__answer">
                      <input required type="radio" id="sad" name="overall" value="sad" onClick={this.storeWhatsupOverallMood} />
                      <label className="whatsup__btn" htmlFor="sad"><IconSmileSad /></label>
                    </div>
                  </div>
                  <Button
                    elem="button"
                    text={translate('WHATSUP_NEXT')}
                  />
                </form>
              </>
            }

            {page === 2 &&
              <form onSubmit={this.next.bind(this)}>
                {currentQuestionnaire && currentQuestionnaire.subquestions.map((question, index) =>
                  <div className="whatsup__question" key={index}>
                    <p className="whatsup__question-header">{question.question_header}</p>
                    <p>{question.question_text}</p>
                    <div className="whatsup__answers">
                      <div className="whatsup__answer">
                        <input required type="radio" id={`${index}happy`} name={index} value="happy" onClick={this.storeWhatsupMood.bind(this, question.uuid, question.question_header, question.question_text)} />
                        <label className="whatsup__btn" htmlFor={`${index}happy`}><IconSmileHappy /></label>
                      </div>
                      <div className="whatsup__answer">
                        <input required type="radio" id={`${index}neutral`} name={index} value="neutral" onClick={this.storeWhatsupMood.bind(this, question.uuid, question.question_header, question.question_text)} />
                        <label className="whatsup__btn" htmlFor={`${index}neutral`}><IconSmileNeutral /></label>
                      </div>
                      <div className="whatsup__answer">
                        <input required type="radio" id={`${index}sad`} name={index} value="sad" onClick={this.storeWhatsupMood.bind(this, question.uuid, question.question_header, question.question_text)} />
                        <label className="whatsup__btn" htmlFor={`${index}sad`}><IconSmileSad /></label>
                      </div>
                    </div>
                  </div>
                )}
                <Button
                  elem="button"
                  text={translate('WHATSUP_NEXT')}
                />
              </form>
            }

            {page === 3 &&
              <form onSubmit={this.storeWhatsupNeeds.bind(this)}>
                <p className="whatsup__text-bold">{translate('WHATSUP_NEEDS_QUESTION')}</p>
                <textarea
                  required
                  className="whatsup__input-needs"
                  name="whatsup-needs"
                  placeholder={translate('WHATSUP_NEEDS_PLACEHOLDER')}
                  onChange={(e) => { this.setState({ whatsupNeeds: e.target.value }); }}
                />
                <Button elem="button" text={translate('WHATSUP_SUBMIT')} />
                <p onClick={this.storeToTimeFrame.bind(this)} className="whatsup__skip">{translate('WHATSUP_SKIP')}</p>
              </form>
            }

            {page === 4 &&
              <>
                {feedback.success &&
                  <div className="success-container">
                    <div className="success-frame">
                      <IconThumbsUp size="48" />
                      <p>{translate('FEEDBACK_SENT')}</p>
                      <Button className="c-button" text={translate('WHATSUP_DONE')} onClick={this.close.bind(this)} />
                    </div>
                  </div>
                }

                <form className={feedback.success && 'hidden'}>
                  <p className="whatsup__text-bold">{translate('WHATSUP_FEEDBACK_QUESTION')}</p>
                  <p className="whatsup__text">{translate('WHATSUP_FEEDBACK_TEXT')}</p>
                  <textarea
                    className="whatsup__input-feedback"
                    name="whatsup-feedback"
                    placeholder={translate('WHATSUP_FEEDBACK_PLACEHOLDER')}
                    value={this.state.message}
                    onChange={(e) => { this.setState({ message: e.target.value }); }}
                  />
                  <Button text={translate('WHATSUP_COMPLETE')} onClick={this.submitFeedback.bind(this)} />
                </form>
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

Whatsup.propTypes = {
  startDate: PropTypes.object,
  currentDate: PropTypes.object,
  questionnairesArray: PropTypes.array,
  history: PropTypes.object,
  close: PropTypes.func,
  postFlexibleFields: PropTypes.func,
  postWhatsup: PropTypes.func,
  sendUserFeedback: PropTypes.func,
  clearUIFeedback: PropTypes.func,
  whatsupLevel: PropTypes.number,
  whatsupNeeds: PropTypes.string,
  whatsupAnswers: PropTypes.string,
  feedback: PropTypes.object,
};

const mapStateToProps = state => {
  const whatsup = state.pages.filter(page => page.title === 'whatsup questionnaire')[0];
  const questionnairesObject = whatsup && whatsup.content;
  const user = state.userData && state.userData.profile_flexible_field;
  const feedback = state.feedback;
  return {
    whatsupLevel: (user && user.whatsup_level) ? user.whatsup_level : 0,
    whatsupAnswers: (user && user.whatsup) ? user.whatsup : '',
    whatsupNeeds: (user && user.whatsup_needs) ? user.whatsup_needs : '',
    startDate: user && new Date(Date.parse(user.start_date)),
    questionnairesArray: questionnairesObject && Object.values(questionnairesObject),
    currentDate: new Date(),
    feedback,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  postFlexibleFields,
  postWhatsup,
  sendUserFeedback,
  clearUIFeedback
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Whatsup);

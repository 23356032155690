import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import { addTodo } from '../../../store/progress/actions';
import { completeModule, selectAnswer, fetchAnswer, activateModule, moduleToBeCompleted, resetQuestion } from '../../../store/module_player/actions';
import { getMPCFeedback, getNeutralFeedback, isMPCCorrect } from 'qollap-lms';
import TemplateContent from './content';
import TemplateChecklist from './checklist';
import TemplateQuiz from './quiz';
import TemplateMPC from './mpc';
import TemplateIntro from './intro';
import TemplateOutro from './outro';
import TemplateBackgroundArticle from './background-article';

const nextPage = ({history, match}) => {
  history.replace(`${parseInt(match.params.index) + 1}`);
};

const closeModule = ({history}) => {
  history.goBack();
};

export default {
  TemplateContent: withRouter(connect(
    (state, ownProps) => ({}),
    (dispatch, ownProps) => ({
      ...bindActionCreators({
        done: completeModule.bind(null, ownProps.match.params.moduleUuid),
      }, dispatch),
      next: nextPage.bind(null, ownProps)
    })
  )(TemplateContent)),

  TemplateBackgroundArticle: withRouter(connect(
    (state, ownProps) => ({
    }),
    (dispatch, ownProps) => ({
      ...bindActionCreators({
        done: completeModule.bind(null, ownProps.match.params.moduleUuid),
      }, dispatch),
      next: nextPage.bind(null, ownProps)
    })
  )(TemplateBackgroundArticle)),

  TemplateChecklist: withRouter(connect(
    (state, ownProps) => {
      const questionData = state.moduleUserData.questions[ownProps.data.checklist.uuid];

      return {
        questionData: questionData,
      }
    },
    (dispatch, ownProps) => ({
      ...bindActionCreators({
        selectAnswer,
        updateAnswer: (answer) => {
          return selectAnswer({
            question: ownProps.data.checklist.uuid,
            answer: answer,
            submit: true,
            reset: true
          });
        },
        fetchAnswer: fetchAnswer.bind(null, ownProps.data.checklist.uuid),
      }, dispatch),
      next: nextPage.bind(null, ownProps)
    })
  )(TemplateChecklist)),

  TemplateQuiz: withRouter(connect(
    (state, ownProps) => {
      const results = ownProps.data.questions.filter(question => state.moduleUserData.questions[question.uuid])
        .map(question => {
          const answer = state.moduleUserData.questions[question.uuid].answer;
          const correct = isMPCCorrect(question, answer);
          const questionText = question.title;
          const correctOptionText = [question.option_1,question.option_2].find(option => option.is_correct).title;
          return {
            correct,
            questionText,
            correctOptionText,
          };
        });

      const correctAnswers = results.filter(result => result.correct).length;

      const allQuestionsAnswered = ownProps.data.questions.every(question => state.moduleUserData.questions[question.uuid]);
      const quizCompleted = allQuestionsAnswered;

      return {
        results,
        correctAnswers,
        quizCompleted
      };
    },
    (dispatch, ownProps) => ({
      ...bindActionCreators({
        done: completeModule.bind(null, ownProps.match.params.moduleUuid),
        selectAnswer,
        updateAnswer: (question, option) => {
          return selectAnswer({
            question: question,
            answer: [{type: 'OPTION', value: option}],
            submit: true
          });
        },
        resetQuestion: (uuid) => {
          return resetQuestion({ uuid });
        }
      }, dispatch),
      fetchAnswers: () => ownProps.data.questions.forEach((question) => dispatch(fetchAnswer(question.uuid))),
      next: nextPage.bind(null, ownProps)
    })
  )(TemplateQuiz)),

  TemplateMPC: withRouter(connect(
    (state, ownProps) => {
      const questionData = state.moduleUserData.questions[ownProps.data.multiple_choice.uuid];
      const isNeutral = ownProps.data.multiple_choice.feedback;
      const mpcFeedback = getMPCFeedback(ownProps.data.multiple_choice, (questionData && questionData.answer));
      const neutralFeedback = getNeutralFeedback(ownProps.data.multiple_choice);
      const answering = state.moduleUserData.answered[ownProps.data.multiple_choice.uuid];

      return {
        questionData: questionData,
        feedback: isNeutral ? neutralFeedback : mpcFeedback,
        neutral: isNeutral,
        answering,
      };
    },
    (dispatch, ownProps) => ({
      ...bindActionCreators({
        selectAnswer,
        updateAnswer: (answer) => {
          return selectAnswer({
            question: ownProps.data.multiple_choice.uuid,
            answer: answer,
            submit: true
          });
        },
        fetchAnswer: fetchAnswer.bind(null, ownProps.data.multiple_choice.uuid)
      }, dispatch),
      next: nextPage.bind(null, ownProps)
    })
  )(TemplateMPC)),

  TemplateIntro: withRouter(connect(
    (state, ownProps) => {
      const todo = state.todos.filter(todo => todo.data.uuid === ownProps.moduleUuid);
      const module = state.moduleContent[ownProps.moduleUuid];
      const journey = state.journeys.filter(journey => journey.id === module.journey);
      return {
        todoAdded: todo.length > 0,
        module,
        journey: journey[0]
      };
    },
    (dispatch, ownProps) => ({
      ...bindActionCreators({
        done: completeModule.bind(null, ownProps.match.params.moduleUuid),
        addTodo,
        activateModule,
      }, dispatch),
      close: closeModule.bind(null, ownProps),
      next: nextPage.bind(null, ownProps)
    })
  )(TemplateIntro)),

  TemplateOutro: withRouter(connect(
    (state, ownProps) => {
      const questionData = state.moduleUserData.questions[ownProps.data.multiple_choice.uuid];

      return {
        questionData: questionData,
      }
    },
    (dispatch, ownProps) => ({
      ...bindActionCreators({
        selectAnswer,
        updateAnswer: (answer) => {
          return selectAnswer({
            question: ownProps.data.multiple_choice.uuid,
            answer: answer,
            submit: true,
            reset: true
          });
        },
        fetchAnswer: fetchAnswer.bind(null, ownProps.data.multiple_choice.uuid),
        done: completeModule.bind(null, ownProps.match.params.moduleUuid),
        moduleToBeCompleted,
      }, dispatch),
      close: closeModule.bind(null, ownProps),
    })
  )(TemplateOutro)),
};

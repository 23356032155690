import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import IntroSlider from './intro-slider';
import Header from '../../components/header';
import './styles.css';


class Intro extends React.Component {
  render() {
    const { slides } = this.props;

    return (
      <div as="section" className={classNames({ 's-intro': true, 'default-view-transition': true })}>
        <div className="intro-bg"></div>
        <Header logo className="header" />
        <div className="intro-container">
          {(slides && slides.length > 0) &&
            <IntroSlider {...this.props } />
          }
        </div>
      </div>
    );
  }
}

Intro.propTypes = {
  firstName: PropTypes.string,
  mediaUrl: PropTypes.string,
  slides: PropTypes.array
};

const mapStateToProps = state => {
  const walkthrough = state.pages.filter(page => page.title === 'walkthrough')[0];
  const slides = walkthrough && walkthrough.content.walkthrough;
  const mediaUrl = walkthrough && walkthrough.meta.media_url;
  const name = state.userData.user;
  const firstName = name && name.first_name;

  return {
    slides, mediaUrl, firstName
  };
};

export default connect(
  mapStateToProps
)(Intro);

import React from 'react';
import PropTypes from 'prop-types';

import Intro from './intro';
import Quiz from './main';
import Results from './results';
import './styles.css';


class TemplateQuiz extends React.Component {

  state = {
    started: false,
    end: false,
    quizCompleted: false
  }

  componentDidMount() {
    this.props.fetchAnswers();
    this.setState({ quizCompleted: this.props.quizCompleted });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.quizCompleted != this.props.quizCompleted) {
      this.setState({ quizCompleted: this.props.quizCompleted });
    }
  }

  start() {
    this.setState({ started: true, end: false, quizCompleted: false });
  }

  end() {
    setTimeout(() => {
      this.setState({ end: true, started: false, quizCompleted: false });
    }, 1000);
  }

  restart() {
    this.setState({ started: false, end: false, quizCompleted: false });
    this.props.data.questions.map(question => {
      this.props.resetQuestion(question.uuid);
    });
  }

  completeQuiz() {
    // trigger only when all questions are answered correctly
    this.props.done();
    this.props.history.goBack();
  }

  done() {
    // trigger when not all questions are answered correctly
    this.props.history.goBack();
  }

  next() {
    this.props.next();
    this.setState({ showNextBtn: false });
  }

  renderScreen() {
    if (this.state.end || this.state.quizCompleted) {
      return (
        <Results
          {...this.props}
          onRestart={this.restart.bind(this)}
          onDone={this.done.bind(this)}
          onCompleted={this.completeQuiz.bind(this)}
          results={this.props.results}
          ended={this.state.end}
        />
      )
    } else if (this.state.started) {
      return (
        <Quiz
          {...this.props}
          cards={this.props.data.questions.map(mpc => ({
            uuid: mpc.uuid,
            title: mpc.title,
            options: [mpc.option_1, mpc.option_2]
          }))}
          updateAnswer={this.props.updateAnswer}
          onRestart={this.restart.bind(this)}
          onDone={this.done.bind(this)}
          onEnd={this.end.bind(this)}
        />
      );
    } else {
      return (
        <Intro
          {...this.props}
          start={this.start.bind(this)}
        />
      );
    }
  }

  render() {
    const { started, end, quizCompleted } = this.state;
    const mode = (end || quizCompleted) ? 'outro' : started ? 'main' : 'intro';

    return (
      <section className={`t-quiz quiz-${mode}`} ref={this.scrollReference}>
        {this.renderScreen()}
      </section>
    );
  }
}

TemplateQuiz.propTypes = {
  data: PropTypes.object,
  resetQuestion: PropTypes.func,
  fetchAnswers: PropTypes.func,
  updateAnswer: PropTypes.func,
  next: PropTypes.func,
  results: PropTypes.array,
  history: PropTypes.object,
  quizCompleted: PropTypes.bool,
  done: PropTypes.func,
};

export default TemplateQuiz;

import React from 'react';
import { translate } from '../../utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { IconCross, IconProfile } from '../../components/icons';
import './styles.css';

class WhoIsWhoModal extends React.Component {


  render() {
    const { imageUrl, name, location, dish, startdate, jobtitle, onClick } = this.props;

    return (
      <section className={classNames({ 'c-who-is-who-modal': true, 'default-view-transition': true })}>
        <div className="overlay">
          <div className="container">
            <div className="top">
              <div className="close" onClick={onClick} >
                <IconCross />
              </div>
              <div className="top__content">
                <div className="img-container">
                  {imageUrl
                    ? <img src={imageUrl} />
                    : <IconProfile />
                  }
                </div>
                <p className="name">{name}</p>
                <p className="jobtitle">{jobtitle}</p>
              </div>
            </div>
            <div className="bottom">
              <div className="bottom__content">
                <div className="info-item">
                  <p className="label">{translate('WHOISWHO_LOCATION')}</p>
                  <p className="value">{location}</p>
                </div>
                <div className="info-item">
                  <p className="label">{translate('WHOISWHO_FAVOURITE_DISH')}</p>
                  <p className="value">{dish}</p>
                </div>
                <div className="info-item">
                  <p className="label">{translate('WHOISWHO_START_DATE')}</p>
                  <p className="value">{startdate}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

WhoIsWhoModal.propTypes = {
  colleagues: PropTypes.array,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  jobtitle: PropTypes.string,
  location: PropTypes.string,
  startdate: PropTypes.string,
  dish: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(WhoIsWhoModal);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';

import { translate } from '../../utils';
import { rootProps } from '../../root';
import { contentToTemplate } from './template-converter';
import { completeModule, fetchModuleContent } from '../../store/module_player/actions';
import LMSConfigurator from '../../components/lms_config';
import Header from '../../components/header';
import trackingActions from '../../store/tracking/actions';


class ModulePlayer extends React.Component {
  modulePlayerReference = React.createRef();

  visits = {};

  state = {
    index: 0,
    branded: false
  };

  constructor(props) {
    super(props);

    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;

    if (!rootProps.lmsPreview || rootProps.scorm) {
      this.props.fetchModuleContent(this.props.match.params.moduleUuid);
      this.props.trackModuleOpened(this.props.match.params.moduleUuid);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate() {
    if (!this.state.branded && this.props.moduleContent) {
      this.setState({ branded: true });
    }

    if (this.modulePlayerReference.current) {
      const anchors = this.modulePlayerReference.current.querySelectorAll(
        'a[href^="http"]:not([target="_blank"])'
      );
      Array.from(anchors).forEach(anchor => {
        anchor.setAttribute('target', '_blank');
      });
    }
  }

  canShowPage(index) {
    return (index >= 0 && index < 1) || this.visits[index - 1];
  }

  getLastVisitedPage() {
    const visited = Object.keys(this.visits).map(parseInt);
    return visited.length > 0 ? Math.max(...visited) + 1 : 1;
  }

  renderTemplate(index, router) {
    const templates = this.props.moduleContent.children;
    const data = templates[index];

    const moduleData = {
      moduleUuid: this.props.moduleContent.uuid,
      moduleIndex: this.props.moduleContent.index,
      moduleTitle: this.props.moduleContent.title,
      moduleStarted: this.props.moduleContent.started,
      moduleCompleted: this.props.moduleContent.completed === 100,
      moduleSetComplete: this.props.moduleContent.set_complete,
      moduleScrollable: false,
      templatePrev: index > 0 ? templates[index - 1].template : null,
      templateNext: index < templates.length - 1 ? templates[index + 1].template : null,
    };

    // Log the visit to this page.
    this.visits[index] = (this.visits[index] || 0) + 1;

    // Return the correct template component.
    return contentToTemplate(moduleData, data, index, router);
  }

  renderSection(template) {
    const currentIndex = parseInt(this.props.match.params.index || 1);
    const content = (template => {
      switch (template) {
        case 'module-longread':
          return [...Array(currentIndex).keys()];
        case 'module-slider':
          return [...Array(currentIndex).keys()].filter(
            index => index + 1 === currentIndex
          );
        case 'module-onesie':
          return [...this.props.moduleContent.children.keys()];
        default:
          return [...Array(currentIndex).keys()];
      }
    })(template);

    if (!this.canShowPage(this.props.match.params.index - 1)) {
      return (
        <Redirect to={`/todo/${this.props.match.params.moduleUuid}/${this.getLastVisitedPage()}`} />
      );
    }

    return (
      <TransitionGroup component={null}>
        {content.map(value => (
          <CSSTransition key={value} timeout={1000} classNames="anim-section">
            {this.renderTemplate.apply(this, [value])}
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  }

  render() {
    const moduleContent = this.props.moduleContent;
    const moduleContentChildren = moduleContent && this.props.moduleContent.children;
    const template = moduleContent && moduleContent.template;
    const helmet = (
      <Helmet>
        <title>{moduleContent && moduleContent.title} - {translate('Take Away')}</title>
      </Helmet>
    );

    return (
      <div className="s-module-player default-view-transition" ref={this.modulePlayerReference}>
        {moduleContentChildren && moduleContentChildren[0].template === 'quiz'
          ?
          <Header
            className="header--quiz"
            hamburger
            hamburgerColor='#fff'
            logo
            logoColor='#fff'
          />
          :
          <Header
            className="header"
            hamburger
            hamburgerColor="var(--body-color)"
            logo
            logoColor="#FF8000"
          />
        }

        {helmet}
        {rootProps.lmsPreview && <LMSConfigurator />}

        {moduleContentChildren
          ? this.renderSection(template)
          : <p className="loading-txt">loading...</p>
        }
      </div>
    );
  }
}

ModulePlayer.propTypes = {
  moduleContent: PropTypes.object,
  fetchModuleContent: PropTypes.func,
  completeModule: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  return {
    moduleContent: state.moduleContent[ownProps.match.params.moduleUuid],
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchModuleContent,
  completeModule,
  trackModuleOpened: uuid => trackingActions.trackModuleOpened(uuid)
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModulePlayer);

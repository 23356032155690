import { all, put, takeLatest, select } from 'redux-saga/effects';

import { getApi } from '../../api';
import * as TYPES from './types';


export function* fetchJourneys() {
  const api = getApi();
  try {
    const response = yield api.fetchJourneys();
    yield put({ type: TYPES.FETCH_JOURNEYS_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_JOURNEYS_FAILED, payload: { message } });
  }
}

export function* generateMagicWall() {
  try {
    const pages = yield select(state => state.pages);
    const journeys = yield select(state => state.journeys);
    const modules = journeys.reduce((out, journey) => [...out, ...journey.modules], []);
    const payload = { modules, pages };
    yield put({ type: TYPES.GENERATE_MAGIC_WALL_SUCCESS, payload });
  } catch ({ message }) {
    yield put({ type: TYPES.GENERATE_MAGIC_WALL_FAILED, payload: { message } });
  }
}

// Listeners.
function* generateMagicWallListener() {
  yield takeLatest(TYPES.GENERATE_MAGIC_WALL, generateMagicWall);
}

export default function* dashboardSaga() {
  yield all([
    generateMagicWallListener(),
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import Cards, { Card } from 'react-swipe-card';
import Thumbup from '../../../../assets/images/asset_thumbsup.svg';
import ThumbDown from '../../../../assets/images/asset_thumbsdown.svg';
import TimeoutPopup from '../../../../components/timeout_popup';
import { IconStopwatch, IconArrowCircleLeft, IconArrowCircleRight } from '../../../../components/icons';
import { translate } from '../../../../utils';
import classNames from 'classnames';

class Quiz extends React.Component {
  state = {
    questionCounter: 0,
    timer: 0,
    showFeedback: false,
    showTimeoutModal: false,
  }

  cards = React.createRef();

  componentDidMount() {
    this.setState({ timer: this.props.data.timer_duration_in_seconds });
    this.timerInterval = setInterval(this.tick.bind(this), 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.timer !== prevState.timer) && this.state.timer <= 0) {
      this.setState({ showTimeoutModal: true });
      clearInterval(this.timerInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  onSwipeLeft(card) {
    this.answerQuestion(card.uuid, card.options[0].uuid, card.options[0].is_correct);
  }

  onSwipeRight(card) {
    this.answerQuestion(card.uuid, card.options[1].uuid, card.options[1].is_correct);
  }

  showFeedback() {
    this.setState({ showFeedback: true });
    setTimeout(() => {
      this.setState({ showFeedback: false });
    }, 1000);
  }

  answerQuestion(questionUuid, optionUuid, optionCorrect, event) {
    this.setState({ questionFeedback: optionCorrect });
    this.showFeedback();
    this.props.updateAnswer(questionUuid, optionUuid);
    event && this.cards.current.removeCard();
    if (this.state.questionCounter + 1 < this.props.cards.length) {
      this.setState({ questionCounter: this.state.questionCounter + 1 });
    }
  }

  getTime(sec) {
    let time = new Date(null);
    time.setSeconds(sec);
    time = sec < 600
      ? time.toISOString().substr(15, 4)
      : time.toISOString().substr(14, 5);
    return time;
  }

  tick() {
    this.setState({ timer: this.state.timer - 1 });
  }

  renderCard(card, index) {
    return (
      <Card
        key={index}
        onSwipeLeft={this.onSwipeLeft.bind(this, card)}
        onSwipeRight={this.onSwipeRight.bind(this, card)}
      >
        <p className="question">{card.title}</p>
      </Card>
    );
  }

  renderOptionsByIndex(index) {
    return (
      <div className="options">
        {this.props.cards[index].options.map((option, i) => (
          <button className="button" key={i} onClick={this.answerQuestion.bind(this, this.props.cards[index].uuid, option.uuid, option.is_correct)}>
            {option.title}
          </button>)
        )}
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <article className="container">
          <p className="progress">{translate('QUIZ_QUESTION_TEXT')} {this.state.questionCounter + 1}/{this.props.cards.length}</p>
          <div className={classNames({ "feedback": true, "is-hidden": !this.state.showFeedback })}>
            {this.state.questionFeedback ? <img className="thumbs-up" src={Thumbup} /> : <img className="thumbs-down" src={ThumbDown} />}
          </div>
          <Cards ref={this.cards} className={'quiz-cards'} onEnd={this.props.onEnd}>
            {this.props.cards.map(this.renderCard.bind(this))}
          </Cards>
          {this.renderOptionsByIndex(this.state.questionCounter)}
          <div className="timer">
            <IconStopwatch/>
            <h1>{this.getTime(this.state.timer)}</h1>
          </div>
        </article>
        <TimeoutPopup
          active={this.state.showTimeoutModal}
          onDone={this.props.onDone}
          onRestart={this.props.onRestart}
        />
      </React.Fragment>
    );
  }
}

Quiz.propTypes = {
  cards: PropTypes.array,
  updateAnswer: PropTypes.func,
  onRestart: PropTypes.func,
  onDone: PropTypes.func,
  onEnd: PropTypes.func,
  data: PropTypes.object,
};

export default Quiz;

import * as TYPES from './types';


export const selectAnswer = (data) => ({
  type: TYPES.ANSWER_SELECT,
  payload: data
});

export const resetQuestion = ({uuid}) => ({
  type: TYPES.RESET_QUESTION,
  uuid
});

export const updateAnswer = (data) => ({
  type: TYPES.ANSWER_UPDATE,
  payload: data
});

export const fetchAnswer = (uuid, complex=false) => ({
  type: TYPES.FETCH_ANSWER,
  uuid,
  complex
});

export const prefillAllAnswers = (data) => ({
  type: TYPES.PREFILL_ALL_ANSWERS,
  payload: data
});

export const clearAllAnswers = () => ({
  type: TYPES.CLEAR_ALL_ANSWERS,
});

export const fetchModules = () => ({
  type: TYPES.FETCH_MODULES,
});

export const fetchModuleContent = (uuid) => ({
  type: TYPES.FETCH_MODULE_CONTENT,
  uuid
});

export const completeModule = (uuid) => ({
  type: TYPES.COMPLETE_MODULE,
  uuid
});

export const moduleToBeCompleted = (data) => ({
  type: TYPES.MODULE_TO_BE_COMPLETED,
  payload: data
});

export const fetchModule = (uuid) => ({
  type: TYPES.FETCH_MODULE,
  uuid
});

export const activateModule = (uuid) => ({
  type: TYPES.MODULE_ACTIVE,
  uuid
});

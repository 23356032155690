import React from 'react';
import PropTypes from 'prop-types';
import { LMSFeedback } from 'qollap-lms';

import { translate } from '../../../utils';
import method from '../work-methods';
import LMSInteraction from '../../../components/lms_interaction';


class TemplateMPC extends React.Component {

  componentDidMount() {
    this.props.next();
  }

  check() {
    this.props.updateAnswer(this.props.questionData.answer);
  }

  render() {
    const { data, feedback, template, questionData, neutral, answering } = this.props;
    const answered = questionData && questionData.state === 'ANSWERED';
    const unanswered = questionData && questionData.state !== 'ANSWERED';
    const classes = [
      's-module-section',
      `t-${template}`,
      `${data.multiple_choice.multi_select ? 'multiselect' : 'singleselect'}`,
      `${neutral ? 'neutral' : ''}`,
      `${(answered && feedback) && 't-mpc--feedback'}`
    ];

    return (
      <section className={classes.join(' ')}>
        <main className="container">
          <div className="inner-container">
            {data.heading && <method.LMSHeading title={data.heading} />}
            {data.text && <method.LMSText text={data.text} />}
            <method.LMSText className="question" text={data.multiple_choice.title} />
            <method.LMSMultipleChoice
              {...this.props}
              userData={questionData}
              uuid={data.multiple_choice.uuid}
              content={data.multiple_choice}
              options={data.multiple_choice.options}
              multiselect={data.multiple_choice.multi_select}
            />
          </div>
          {!answered &&
            <LMSInteraction
              visible
              disabled={answering}
              check
              className={unanswered && 'state-active'}
              text={translate('Feedback')}
              onClick={unanswered && this.check.bind(this)}
            />
          }
          {(answered && feedback) && <LMSFeedback {...feedback} />}
        </main>
      </section>
    );
  }
}

TemplateMPC.propTypes = {
  template: PropTypes.string,
  updateAnswer: PropTypes.func,
  questionData: PropTypes.object,
  next: PropTypes.func,
  data: PropTypes.object,
  feedback: PropTypes.object,
};

export default TemplateMPC;

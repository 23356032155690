import React from 'react';
import { translate } from '../../utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchReport } from '../../store/app/actions';

import Header from '../../components/header';
import Loader from '../../components/loader';
import ReportingUser from './reporting_user';
import './styles.css';

class Reporting extends React.Component {
  // fetch user data from all users linked to you as a manager
  fetchReport() {
    this.props.fetchReport();
  }

  // calculates overall progress based on module completion. Used per user.
  calculateProgress(modules) {
    const allModules = modules.length;
    let completedModules = modules.filter(mod => mod.completed === true);
    const progress = completedModules.length / allModules * 100;
    return progress;
  }

  componentDidMount() {
    this.fetchReport();
  }

  render() {
    const { employees } = this.props;
    const loading = !employees;
    return (
      <section className={classNames({ 's-reporting': true, 'default-view-transition': true })}>
        <Header
          className="header"
          hamburger
          logo
          logoColor="var(--color-1)"
          hamburgerColor="var(--body-color)"
        />

        <section className="s-reporting-container">
          <div className="s-reporting-content">
            <h1>{translate('REPORTING_TITLE')}</h1>
            <h4>{translate('REPORTING_SUBTITLE')}</h4>

            <div className="s-reporting__employees">
              {employees && employees.map((employee) =>
                <ReportingUser
                  key = {employee.pk}
                  id = {employee.pk}
                  name = {employee.name}
                  jobtitle = {employee.subtitle}
                  imageUrl = {employee.image}
                  progress = {this.calculateProgress(employee.invitations)}
                  whatsupstring = {employee.flexible_field.whatsup}
                />
              )}
            </div>
          </div>
        </section>
        <Loader text={translate('APP_LOADER_MSG')} loading={loading} />
      </section>
    );
  }
}

Reporting.propTypes = {
  fetchReport: PropTypes.func,
  employees: PropTypes.array,
};

const mapStateToProps = state => {
  const employees = state.report && state.report.users;

  return {
    employees,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchReport
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);

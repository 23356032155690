import * as TYPES from './types';

export const postRegister = (payload) => ({
  type: TYPES.POST_REGISTER,
  payload
});

export const postLogin = (payload) => ({
  type: TYPES.POST_LOGIN,
  payload
});

export const logout = () => ({
  type: TYPES.LOGOUT
});

export const resetPassword = (payload) => ({
  type: TYPES.RESET_PASSWORD,
  email: payload.email,
});

export const confirmPassword = (payload) => ({
  type: TYPES.CONFIRM_PASSWORD,
  register: payload.register,
  payload: {
    uid: payload.uid,
    token: payload.token,
    new_password: payload.new_password,
    new_password_repeat: payload.new_password_repeat,
  }
});

export const fetchInvite = (payload) => ({
  type: TYPES.FETCH_INVITE,
  payload
});

export const updateFirstVisit = () => ({
  type: TYPES.UPDATE_INTRO,
  payload: {
    first_visit: false
  }
});

export const fetchUserData = () => {
  return {
    type: TYPES.FETCH_USER_DATA
  };
};

export const changeLanguage = (language) => ({
  type: TYPES.CHANGE_LANGUAGE,
  payload: {
    language
  }
});

export const sendUserFeedback = ( message ) => ({
  type: TYPES.SEND_USER_FEEDBACK,
  payload: {
    message
  }
});

export const postWhatsup = payload => ({
  type: TYPES.POST_WHATSUP,
  payload
});

export const postFlexibleFields = (payload) => ({
  type: TYPES.POST_FLEXIBLE_FIELDS,
  payload
});

export const clearUIFeedback = () => ({
  type: TYPES.CLEAR_UI_FEEDBACK,
});

export const fetchReport = () => ({
  type: TYPES.FETCH_REPORT,
});

export const fetchNotifications = () => ({
  type: TYPES.FETCH_NOTIFICATIONS,
});

export const seenAllNotifications = () => ({
  type: TYPES.SEEN_ALL_NOTIFICATIONS,
});

export const fetchColleagues = () => ({
  type: TYPES.FETCH_COLLEAGUES,
});

export const clearHelpNeeded = (userId) => ({
  type: TYPES.CLEAR_HELP_NEEDED,
  payload: {userId}
})

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from '../../utils';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import { IconProfile } from '../../components/icons';
import './styles.css';

class Settings extends React.Component {
  render() {
    const { username, profileImageURL, jobtitle, favourite_dish, emailNotificationSetting } = this.props;

    return (
      <section className={classNames({ 's-settings': true, 'default-view-transition': true })}>
        <Header className="header" hamburger logo />

        <section className="container">
          <h1>{translate('SETTINGS_TITLE')}</h1>

          <div className="s-settings__email">
            <p className="s-settings__label">Email notifications:</p>
            <p className="s-settings__input">{emailNotificationSetting && emailNotificationSetting.send ? "On" : "Off"}</p>
          </div>

          <div className="s-settings__profile">
            <h1>{translate('SETTINGS_PROFILE_TITLE')}</h1>

            <div className="s-settings__primary-info">
              <div className="s-settings__profile-img">
                <div className="s-settings__img-container">
                  {profileImageURL ? <img src={profileImageURL} alt="profile-photo" />
                    : <IconProfile />
                  }
                </div>
              </div>
              <div className="s-settings__text-container">
                <h3 className="s-settings__name">{username}</h3>
                <p className="s-settings__jobtitle">{jobtitle}</p>
              </div>
            </div>

            <div className="s-settings__secondary-info">
              {/* {location &&
                <div className="s-settings__item">
                  <p className="s-settings__label">{translate('SETTINGS_LOCATION')}</p>
                  <p className="s-settings__input">{location}</p>
                </div>
              } */}
              <div className="s-settings__item">
                <p className="s-settings__label">{translate('SETTINGS_DISH')}</p>
                <p className="s-settings__input">{favourite_dish}</p>
              </div>
              {/* {startDate &&
                <div className="s-settings__item">
                  <p className="s-settings__label">{translate('SETTINGS_START_DATE')}</p>
                  <p className="s-settings__input">{startDate}</p>
                </div>
              } */}
            </div>
          </div>

          <Link to="/settings/edit" className="c-button">{translate('EDIT_PROFILE')}</Link>

        </section>
      </section>
    );
  }
}

Settings.propTypes = {
  // startDate: PropTypes.string,
  // location: PropTypes.string,
  username: PropTypes.string,
  jobtitle: PropTypes.string,
  email_notifications: PropTypes.bool,
  profileImageURL: PropTypes.string,
  favourite_dish: PropTypes.string,
};

const mapStateToProps = state => {
  const user = state.userData && state.userData;

  return {
    profileImageURL: user && user.image,
    // location: user && user.profile_flexible_field.import_data.Location,
    username: user && `${user.user.first_name} ${user.user.last_name}`,
    // startDate: user && new Date(user.profile_flexible_field.start_date).toLocaleDateString(),
    jobtitle: user && user.subtitle,
    favourite_dish: user && user.profile_flexible_field.favourite_dish,
    emailNotificationSetting: state && state.settings && state.settings.find(noticeSetting => noticeSetting.notice_type_display === "eca_add_notification_action_email")
  };
};

export default connect(mapStateToProps)(Settings);

import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/button';
import img from '../../../../../src/assets/images/asset_quiz_intro.svg';

import { translate } from '../../../../utils';

class Intro extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <article className="container">
        <div className="intro-block">
          <img className="image" src={img} alt="quiz cover"/>
          <h1 className="heading">{data.intro_heading}</h1>
          <div className="text" dangerouslySetInnerHTML={{ __html: data.intro_description }} />
          <Button text={translate('QUIZ_START_BUTTON')} onClick={this.props.start} />
        </div>
      </article>
    );
  }
}

Intro.propTypes = {
  data: PropTypes.object,
  start: PropTypes.func,
};

export default Intro;

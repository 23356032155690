import { store } from '../store';
import { getApi } from '../api';


function translate(identifier, obj, named) {
  const translations = store.getState().translations;
  let fmt;
  if(!translations.data) {
    fmt = identifier
  } else {
    fmt = translations.data[identifier];
  }
  if(!fmt && translations.loaded) {
    getApi().apiPromise.then(api => {
      api.action(['localization', 'translatable_item', 'create'], {'identifier': identifier})
    })
    return identifier
  }
  if (named) {
    return fmt.replace(/%\(\w+\)s/g, function(match){return String(obj[match.slice(2,-2)])});
  } else {
    return fmt.replace(/%s/g, function(match){return String(obj.shift())});
  }
}


export {
  translate,
};

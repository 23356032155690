import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  createRandomTodo,
  generateMagicWall,
} from '../../store/dashboard/actions';
import { translate } from '../../utils';
import Module from '../../components/module';
import Header from '../../components/header';
import MagicWall from '../../components/magic_wall';
import './styles.css';

class Dashboard extends React.Component {
  state = {};

  getFeaturedModules() {
    // get dates.
    const startDate = this.props.startDate;
    const currentDate = this.props.currentDate;

    // calculate difference in weeks between startdate and current date.
    let week = (currentDate.getTime() - startDate.getTime()) / 1000;
    week /= 60 * 60 * 24 * 7;
    week = Math.round(week);

    // get current timeframe.
    let timeFrame = null;
    // short program starts august 1st 2021
    const shortprogramDate = new Date(2021, 7, 1); // month is 0-indexed
    if (startDate >= shortprogramDate) {
      timeFrame = week <= 0 ? 0 : week;
    } else {
      timeFrame = 
        week <= 0
          ? 0
          : week === 1
          ? 1
          : week === 2
          ? 2
          : week >= 3 && week < 5
          ? 3
          : week >= 5 && week < 9
          ? 5
          : week >= 9 && week < 13
          ? 9
          : week >= 13
          ? 13
          : null;
    }

    // filter modules of current timeframe.
    const modules = this.props.journeys
      .reduce((out, journey) => [...out, ...journey.modules], [])
      .filter(
        (module) => parseInt(module.meta.onboarding_time_frame) === timeFrame
      )
      .filter((module) => parseInt(module.completed) !== 100);

    return {
      modules,
      timeFrame,
    };
  }

  componentDidMount() {
    if (this.props.journeys.length > 0) {
      this.props.createRandomTodo(this.props.journeys);
      this.setState({ featured: this.getFeaturedModules() });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.journeys.length > 0 &&
      prevProps.journeys !== this.props.journeys
    ) {
      this.props.createRandomTodo(this.props.journeys);
      this.setState({ featured: this.getFeaturedModules() });
    }
  }

  render() {
    const { journeys } = this.props;
    const { featured } = this.state;

    return (
      <section
        className={classNames({
          's-dashboard': true,
          'default-view-transition': true,
        })}
      >
        <Header className="header" hamburger logo />

        <section className="container">
          <MagicWall generateMagicWall={this.props.generateMagicWall} />

          {featured && (
            <div className="dashboard-suggested-bites">
              <h2 className="dashboard-title">
                {translate(
                  `DASHBOARD_SUGGESTED_BITES_TITLE_${featured.timeFrame}`
                )}
              </h2>
              {featured.modules.length > 0 ? (
                <div className="modules">
                  {featured.modules.map((module, index) => (
                    <Module
                      key={index}
                      uuid={module.uuid}
                      className={'module'}
                      title={module.meta.heading}
                      journey={module.parent}
                      duration={module.meta.duration}
                      mandatory={module.meta.mandatory_module}
                      img={module.imageUrl}
                      completed={module.completed === 100}
                    />
                  ))}
                </div>
              ) : (
                <div className="modules-empty">
                  <p>{translate('DASHBOARD_SUGGESTED_BITES_EMPTY')}</p>
                </div>
              )}
            </div>
          )}

          <section className="dashboard-goals">
            <h2 className="dashboard-title">
              {translate('DASHBOARD_GOALS_TITLE')}
            </h2>
            <div className="goals-outer-container">
              <div className="goals-inner-container">
                {journeys && journeys.length > 0 ? (
                  journeys.map((journey, index) => (
                    <Link
                      to={`journey/${journey.id}`}
                      key={index}
                      className="goal"
                    >
                      <div className="goal-img-container">
                        <img
                          className="goal-img"
                          src={journey.imageUrl}
                          alt="journey cover"
                        ></img>
                      </div>
                      <div className="goal-bottom-container">
                        <h2 className="goal-title">{journey.title}</h2>
                        <p className="goal-description">
                          {journey.description}
                        </p>
                      </div>
                      <div className="progress-bar">
                        <div
                          className="progress-fill"
                          style={{
                            width: `${journey.progress}%`,
                            background:
                              journey.progress === 100 ? '#46D35F' : '#1574F5',
                          }}
                        ></div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <p className="loading-txt">Loading...</p>
                )}
              </div>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

Dashboard.propTypes = {
  startDate: PropTypes.object,
  currentDate: PropTypes.object,
  randomTodoUuid: PropTypes.string,
  journeys: PropTypes.array,
  createRandomTodo: PropTypes.func,
  generateMagicWall: PropTypes.func,
};

const mapStateToProps = (state) => {
  const user = state.userData && state.userData.profile_flexible_field;
  return {
    journeys: state.journeys,
    randomTodoUuid: state.randomTodo.uuid,
    startDate: user && new Date(Date.parse(user.start_date)),
    currentDate: new Date(),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createRandomTodo,
      generateMagicWall,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

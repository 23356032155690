import React from 'react';
import PropTypes from 'prop-types';

import { IconTip } from '../icons';
import './styles.css';


class LMSTip extends React.Component {
  render() {
    const { className, text } = this.props;

    return (
      <div className={'lms-tip' + (className ? ` ${className}` : '')}>
        <IconTip size="35" />
        <p>{text}</p>
      </div>
    );
  }
}

LMSTip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default LMSTip;

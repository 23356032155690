import React from 'react';
import { translate } from '../../utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconLocation, IconProfile } from '../../components/icons';
import './styles.css';

class WhoIsWhoUser extends React.Component {


  render() {
    const { imageUrl, name, jobtitle, location, onClick} = this.props;

    return (
      <section className={classNames({ 'c-who-is-who-user': true, 'default-view-transition': true })} onClick={onClick}>
        <div className="img-container">
          {imageUrl ? <img src={imageUrl} />
            : <IconProfile />
          }
        </div>
        <div className="text">
          <p className="name">{name}</p>
          <p className="jobtitle">{jobtitle}</p>
          <div className="location">
            {location &&
              <IconLocation />
            }
            <p>{location}</p>
          </div>
        </div>
      </section>
    );
  }
}

WhoIsWhoUser.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  jobtitle: PropTypes.string,
  location: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(WhoIsWhoUser);

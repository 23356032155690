import React from 'react';
import { translate } from '../../utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchColleagues } from '../../store/app/actions';

import Header from '../../components/header';
import WhoIsWhoUser from './who_is_who_user';
import WhoIsWhoModal from './who_is_who_modal';
import './styles.css';

class WhoIsWho extends React.Component {
  state = {
    modal: false,
    colleague: {
      imageUrl: '',
      name: '',
      jobtitle: '',
      location: '',
      dish: '',
      startdate: '',
    }
  };

  showModal(colleague) {
    this.setColleagueInfo(colleague);
    this.setState({ modal: true });
  }

  hideModal() {
    this.setState({ modal: false });
  }

  setColleagueInfo(colleague) {
    this.setState({
      colleague: {
        imageUrl: colleague.image,
        name: `${colleague.first_name} ${colleague.last_name}`,
        jobtitle: colleague.subtitle,
        location: colleague.city,
        dish: colleague.flexible_field.favourite_dish,
        startdate: colleague.flexible_field.start_date,
      }
    });
  }

  componentDidMount() {
    this.props.fetchColleagues();
  }

  render() {
    const { colleaguesSorted } = this.props;
    const { modal, colleague } = this.state;

    return (
      <section className={classNames({ 's-who-is-who': true, 'default-view-transition': true })}>
        { modal &&
          <WhoIsWhoModal
            imageUrl={colleague.imageUrl}
            name={colleague.name}
            jobtitle={colleague.jobtitle}
            location={colleague.location}
            dish={colleague.dish}
            startdate={new Date(colleague.startdate).toLocaleDateString()}
            onClick={this.hideModal.bind(this)}
          />
        }

        <Header
          className="header"
          hamburger
          logo
          logoColor="var(--color-1)"
          hamburgerColor="var(--body-color)"
        />

        <section className="s-who-is-who__container">
          <div className="s-who-is-who__content">
            <h1>{translate('WHO_IS_WHO_TITLE')}</h1>
            <h4>{translate('WHO_IS_WHO_SUBTITLE')}</h4>

            <div className="s-who-is-who__list">
              {colleaguesSorted && colleaguesSorted.length > 0
                ? colleaguesSorted.map((colleague, index) =>
                  <WhoIsWhoUser
                    key={index}
                    imageUrl={colleague.image}
                    name={`${colleague.first_name} ${colleague.last_name}`}
                    jobtitle={colleague.subtitle}
                    location={colleague.city}
                    onClick={this.showModal.bind(this, colleague)}
                  />
                )
                : <p>{translate('WHO_IS_WHO_EMPTY')}</p>
              }
            </div>
          </div>
        </section>
      </section>
    );
  }
}

WhoIsWho.propTypes = {
  fetchColleagues: PropTypes.func,
  hideModal: PropTypes.func,
  showModal: PropTypes.func,
  colleaguesSorted: PropTypes.array,
};

const mapStateToProps = state => {
  const colleagues = state.colleagues;
  // Change object array into an actual array, then sort by start date achronologically
  const colleaguesSorted = colleagues.length && colleagues.filter(c => !!c.flexible_field.start_date).sort((a,b) => (new Date(b.flexible_field.start_date) - new Date(a.flexible_field.start_date)));

  return {
    colleaguesSorted,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchColleagues
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WhoIsWho);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { translate } from '../../utils';
import Button from '../button';
import './styles.css';


class MagicWall extends React.Component {
  state = {}

  componentDidMount() {
    this.props.generateMagicWall();
  }

  response(answer) {
    this.setState({ suggestion: answer });
  }

  render() {
    const { className, slide, firstName } = this.props;
    const { suggestion } = this.state;
    const type = slide.type;

    return (
      <article className={classNames({ 's-magic-wall': true, [className]: className })}>
        <svg className='magic-wall-bg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none">
          <polygon fill="white" points="0,100 100,85 100,0 100,100"/>
        </svg>
        <div className='magic-wall-content'>
          <p className="top-text">
            {`${type === 'tip' ? translate('How?') : type === 'fun_fact' ? translate('What?') : `Hi ${firstName}`}`}
          </p>

          <h2 className={`${type !== 'question' ? 'magic-heading-small' : 'magic-heading-large'}`}>
            {suggestion ? suggestion.response_text : slide.text}
          </h2>

          {(type === 'question' && !suggestion) &&
            <ul className="options">
              {slide.responses.map((response, i) =>
                <li className="options__option" key={i} onClick={this.response.bind(this, response)}>
                  {response.button_response_text}
                </li>
              )}
            </ul>
          }

          {suggestion &&
            <div className="suggestion">
              <p className="suggestion__text">{suggestion.module.meta.heading}</p>
              <Button className="button" text={suggestion.button_start_text} link={`/todo/${suggestion.module.uuid}`} />
            </div>
          }
        </div>
      </article>
    );


  }
}

MagicWall.propTypes = {
  className: PropTypes.string,
  firstName: PropTypes.string,
  slide: PropTypes.object,
  generateMagicWall: PropTypes.func,
};

const mapStateToProps = state => {
  const slide = state.magicWall;
  const firstName = state.userData.user && state.userData.user.first_name;
  return {
    slide,
    firstName,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MagicWall);

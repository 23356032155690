import { all, takeLatest, put } from 'redux-saga/effects';
import { goBack } from 'connected-react-router';

import { getApi } from '../../api';
import * as TYPES from './types';


export function* fetchTodos() {
  const api = getApi();
  try {
    const response = yield api.fetchTodos();
    yield put({ type: TYPES.FETCH_TODOS_SUCCESS, payload: response });
  } catch ({ message }) {
    yield put({ type: TYPES.FETCH_TODOS_FAILED, message });
  }
}

export function* postTodo({payload}) {
  const api = getApi();
  try {
    yield api.postTodo(payload);
    yield put({ type: TYPES.POST_TODO_SUCCESS });
    yield fetchTodos();
    yield put(goBack());
  } catch ({ message }) {
    yield put({ type: TYPES.POST_TODO_FAILED, message });
  }
}

export function* completeTodo({ payload }) {
  const api = getApi();
  yield put({ type: TYPES.COMPLETE_TODO });
  try {
    yield api.completeTodo(payload.pk);
    yield put({ type: TYPES.COMPLETE_TODO_SUCCESS });
    yield fetchTodos();
  } catch ({ message }) {
    yield put({ type: TYPES.COMPLETE_TODO_FAILED, message });
  }
}

function* removeTodo({ payload }) {
  const api = getApi();
  try {
    yield api.removeTodo(payload.pk);
    yield put({ type: TYPES.REMOVE_TODO_SUCCESS });
    yield fetchTodos();
  } catch ({ message }) {
    yield put({ type: TYPES.REMOVE_TODO_FAILED, message });
  }
}

function* removeTodoListener() {
  yield takeLatest(TYPES.REMOVE_TODO, removeTodo);
}

function* postTodoListener() {
  yield takeLatest(TYPES.POST_TODO, postTodo);
}


export default function* progressSaga() {
  yield all([
    postTodoListener(),
    removeTodoListener(),
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postFlexibleFields } from '../../store/app/actions';

import { translate } from '../../utils';
import Button from '../../components/button';
import CelebrationAsset from '../../assets/images/asset_celebration.svg';
import './styles.css';

class CelebrationPopup extends React.Component {
  state = {
    active: false,
  }

  showCelebrationModal = () => {
    if(this.props.celebration.current && this.props.celebration.current.length > 0) {
      this.setState({ active: true });
    } else {
      return;
    }
  }

  hideCelebrationModal = () => {
    this.setState({ active: false });
  }

  close(e) {
    e.preventDefault();
    const updatedCelebrations = [...this.props.celebration.seen, ...this.props.celebration.current];
    const updatedCelebrationsString = JSON.stringify(updatedCelebrations);
    this.props.postFlexibleFields({ celebrations_seen: updatedCelebrationsString });
    this.hideCelebrationModal();
  }

  componentDidUpdate(prevProps) {
    if(this.props.celebration.current !== prevProps.celebration.current) {
      this.showCelebrationModal();
    } else {
      return;
    }
  }

  render() {
    const { active } = this.state;
    const { celebration } = this.props;

    return active && (

      <div className="c-celebration">
        <div className="c-celebration__container">
          <div className="c-celebration__content">
            <img src={CelebrationAsset} />
            {celebration.current.includes(100) ?
              <p className="c-celebration__text">{translate('CELEBRATION_TEXT_100')}</p>
              : celebration.current.includes(50) ? <p className="c-celebration__text">{translate('CELEBRATION_TEXT_50')}</p>
                : <p className="c-celebration__text">{translate('CELEBRATION_TEXT_JOURNEY_COMPLETE')}</p>
            }
            <Button className="c-button" onClick={this.close.bind(this)} text={translate('CELEBRATION_THANKS')} />
          </div>
        </div>
      </div>
    );
  }
}

CelebrationPopup.propTypes = {
  celebration: PropTypes.object,
  close: PropTypes.func,
  postFlexibleFields: PropTypes.func,
  hideModal: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    celebration: state.celebration,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  postFlexibleFields,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CelebrationPopup);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { translate } from '../../utils';
import './styles.css';

class Message extends React.Component {
  render() {
    const { text, active, success, error } = this.props;

    return active ?
      <div className={classnames({'c-message': true, 'success': success, 'error': error})}>
        <p>{translate(text)}</p>
      </div>
      : null;
  }
}

Message.propTypes = {
  success: PropTypes.bool,
  error: PropTypes.bool,
  active: PropTypes.bool,
  text: PropTypes.string,
};

export default Message;
